import React from "react";
import { useNavigate } from "react-router-dom";
import HomePageComponent from "../components/HomePageComponent";

const HomePage = () => {
  const navigate = useNavigate();

  // Check if nineToken exists in localStorage
  const nineToken = localStorage.getItem("nineToken");

  // If nineToken doesn't exist, redirect to the login page
  if (!nineToken) {
    window.location.href = "/login";
    return null; // You might want to return null to avoid rendering the component content
  }

  return (
    <div>
      {/* Render the home page content */}
      <HomePageComponent />
    </div>
  );
};

export default HomePage;
