import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AddManager from "./components/AddManager";
import AddPlayer from "./components/AddPlayer";
import AddTeam from "./components/AddTeam";
import MyPlayer from "./components/MyPlayer";
import AddUser from "./components/AddUser";
import GeneralSettings from "./components/GeneralSettings";
import PositionList from "./components/PositionList";
import MobileSidebar from "./components/MobileSidebar";
import Budget from "./components/Budget";
import TeamsBoard from "./components/TeamsBoard";
import Coaches from "./components/Coaches";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginPage from "./components/login";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/add-managers" element={<AddManager />} />
          <Route path="/add-player" element={<AddPlayer />} />
          <Route path="/add-team" element={<AddTeam />} />
          <Route path="/team-board/:id" element={<TeamsBoard />} />
          <Route path="/my-player" element={<MyPlayer />} />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/general-settings" element={<GeneralSettings />} />
          <Route path="/position-list" element={<PositionList />} />
          <Route path="/mobile" element={<MobileSidebar />} />
          <Route path="/coaches" element={<Coaches />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/budget/:id" exact element={<Budget />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
