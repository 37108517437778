import React, { useEffect, useState } from "react";
import arrow1 from "../assets/arrow1.png";
import profile1 from "../assets/profile1.png";
import { IoIosNotifications } from "react-icons/io";
import {
  IoSearchOutline,
  IoSettings,
  IoSearch,
  IoTrashOutline,
} from "react-icons/io5";
import Sidebar from "./Sidebar";
import MobileTopNav from "./MobileTopNav";
import { FaPenClip } from "react-icons/fa6";
import CoachesTable from "./CoachesTable";
import axios from "axios";
import { toast } from "react-toastify";
import ImageComponent from "./ImageComponent";

const Coaches = () => {
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [coaches, setCoaches] = useState([]);
  // Add these state variables
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  // State variables for the form
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    position: "",
    image: "",
    nationality: "",
    salary: "",
    monthly_payment: "",
    bonus: "",
  });

  const fetchPositionData = async () => {
    try {
      const response = await fetch("https://admin.smguld.com/api/v1/coaches");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setCoaches(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  useEffect(() => {
    fetchPositionData();
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  const handleResultsPerPageChange = (e) => {
    setResultsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing results per page
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page when changing the search query
  };

  const indexOfLastUser = currentPage * resultsPerPage;
  const indexOfFirstUser = indexOfLastUser - resultsPerPage;

  const filteredUsers = coaches.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      setSelectedFile(file);

      // Update formData with the selected image
      setFormData({
        ...formData,
        image: file.name, // Assuming you want to store the image name in the formData
      });

      // Preview the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setFilePreview(null);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      // Prepare form data
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("name", formData.name);
      formDataToSubmit.append("age", formData.age);
      formDataToSubmit.append("position", formData.position);
      formDataToSubmit.append("salary", formData.salary);
      formDataToSubmit.append("nationality", formData.nationality);
      formDataToSubmit.append("monthly_payment", formData.monthly_payment);
      formDataToSubmit.append("bonus", formData.bonus);
      formDataToSubmit.append("image", selectedFile);

      const response = await axios.post(
        "https://admin.smguld.com/api/v1/coaches",
        formDataToSubmit
      );

      if (response.status !== 201) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // If successful, you can handle the response as needed
      const data = response.data;
      // If successful, show a success toast notification
      toast.success("Created successfully!", {
        autoClose: 3000, // Time to close the notification in milliseconds
      });
      // Optionally, you can reset the form after submission
      setFormData({
        name: "",
        age: "",
        position: "",
        nationality: "",
        monthly_payment: "",
        bonus: "",
        salary: "",
      });

      // Close the modal
      toggleModal();
    } catch (error) {
      toast.error("Error creating Coaches. Please try again!", {
        autoClose: 3000,
      });
    }
  };

  const options = Array.from(
    { length: Math.ceil(filteredUsers.length / 2) },
    (_, i) => i + 1
  );

  //deletion Method
  const handleDeleteUser = async (userId) => {
    try {
      const response = await fetch(
        `https://admin.smguld.com/api/v1/coaches/${userId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            // You may need to include authorization headers if required
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // If successful, show a success toast notification
      toast.success("Coaches Deleted successfully!", {
        autoClose: 3000, // Time to close the notification in milliseconds
      });
      // If the deletion is successful, you may want to refresh the user list
      fetchPositionData();
    } catch (error) {
      console.error("Delete error:", error);
      toast.error("Error while Deleting. Please try again!", {
        autoClose: 3000,
      });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3 bg-left-color px-4 py-3 height-side-bar main-sidebar-mobile">
          <div className="bg-left-color-text">
            <Sidebar />
          </div>
        </div>
        <div className="col-md-8 height-full">
          <div className="pt-4 row">
          <div className="col-md-1 mt-2 text-center">
            
            <ImageComponent/>
            </div>
            <div className="col-md-8">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-3 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 bg-left-color-card">
                <div className="container py-3 row">
                  <h5 className="col-md-6 mt-1">Coaches</h5>
                  <h5 className="col-md-4">
                    <div class="input-group">
                      <input
                        className="form-control border"
                        type="search"
                        placeholder="Search by name"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <span class="input-group-append">
                        <button
                          class="btn btn-outline-secondary border-start-0 border-bottom-0  border ms-n5"
                          type="button"
                        >
                          <IoSearch />
                        </button>
                      </span>
                    </div>
                  </h5>
                  <div
                    className="btn btn-secondary col-md-2 pt-2 h-50"
                    onClick={toggleModal}
                  >
                    Add Coaches
                  </div>
                </div>

                <div className="container row pb-3">
                  <CoachesTable
                    paginatedUsers={paginatedUsers}
                    indexOfFirstUser={indexOfFirstUser}
                    handleDeleteUser={handleDeleteUser}
                  />
                </div>

                <div className="container d-flex pb-3 justify-content-start">
                  Showing {paginatedUsers.length} of {filteredUsers.length}
                  results
                  <select
                    id="selection"
                    className="mx-2"
                    onChange={handleResultsPerPageChange}
                    value={resultsPerPage}
                  >
                    {options.map((option) => (
                      <option key={option} value={option * 2}>
                        {option * 2}
                      </option>
                    ))}
                  </select>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* mobile view */}
        <div className="col-md-12 height-full-add-player">
          <div className="pt-4 row mobile-view-top-nav">
            <ImageComponent/>
            <div className="col-md-8">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-3 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container">
            <div
              className="row"
              style={{ justifyContent: "center", marginLeft: "10px" }}
            >
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-3 bg-left-color-card">
                <div className="container py-3 row">
                  <h5 className="col-md-6 mt-1">Coaches</h5>
                  <h5 className="col-md-4">
                    <div class="input-group">
                      <input
                        class="form-control border"
                        type="search"
                        placeholder="john doe"
                        id="example-search-input"
                      />
                      <span class="input-group-append">
                        <button
                          class="btn btn-outline-secondary border-start-0 border-bottom-0  border ms-n5"
                          type="button"
                        >
                          <IoSearch />
                        </button>
                      </span>
                    </div>
                  </h5>
                  <div
                    className="btn btn-secondary col-md-2 pt-2 h-50"
                    onClick={toggleModal}
                  >
                    Add Coaches
                  </div>
                </div>

                <div
                  className="container row pb-3"
                  style={{ overflow: "auto" }}
                >
                 <CoachesTable
                    paginatedUsers={paginatedUsers}
                    indexOfFirstUser={indexOfFirstUser}
                    handleDeleteUser={handleDeleteUser}
                  />
                </div>

                <div className="container d-flex pb-3 justify-content-start">
                  Showing {paginatedUsers.length} of {coaches.length} results
                  <select
                    id="selection"
                    className="mx-2"
                    onChange={handleResultsPerPageChange}
                    value={resultsPerPage}
                  >
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                    {/* Add other options as needed */}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Bootstrap modal for adding teams */}
        <div
          className={`modal ${showModal ? "show" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ display: showModal ? "block" : "none" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create User</h5>
              </div>
              <div className="modal-body">
                {/* Add your form elements for adding a team here */}
                <form>
                  <div class="mb-3">
                    <label htmlFor="name" className="form-label">
                      Coach Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={formData.name}
                      placeholder="Enter Full Name"
                      onChange={handleInputChange}
                      name="name"
                    />
                  </div>
                  <div class="mb-3">
                    <label htmlFor="age" className="form-label">
                      Coach Age
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="age"
                      value={formData.age}
                      placeholder="Enter Full age"
                      onChange={handleInputChange}
                      name="age"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="image" className="form-label">
                      Coach Image
                    </label>
                    <input
                      type="file"
                      required
                      className="form-control"
                      id="image"
                      onChange={handleImageChange}
                      name="image"
                    />
                    {filePreview && (
                      <img
                        src={filePreview}
                        alt="Preview"
                        style={{ width: "50px", height: "50px" }}
                      />
                    )}
                  </div>

                  <div class="mb-3">
                    <label htmlFor="position" className="form-label">
                      Coach Position
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="position"
                      value={formData.position}
                      placeholder="Enter Full position"
                      onChange={handleInputChange}
                      name="position"
                    />
                  </div>
                  <div class="mb-3">
                    <label htmlFor="salary" className="form-label">
                      Coach Salary
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="salary"
                      value={formData.salary}
                      placeholder="Enter Full salary"
                      onChange={handleInputChange}
                      name="salary"
                    />
                  </div>
                  <div class="mb-3">
                    <label htmlFor="monthly_payment" className="form-label">
                      Monthly Payment
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="monthly_payment"
                      value={formData.monthly_payment}
                      placeholder="Enter Full monthly_payment"
                      onChange={handleInputChange}
                      name="monthly_payment"
                    />
                  </div>
                  <div class="mb-3">
                    <label htmlFor="bonus" className="form-label">
                      Bonus
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="bonus"
                      value={formData.bonus}
                      placeholder="Enter Full bonus"
                      onChange={handleInputChange}
                      name="bonus"
                    />
                  </div>
                  <div class="mb-3">
                    <label htmlFor="nationality" className="form-label">
                      Coach Nationality
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nationality"
                      value={formData.nationality}
                      placeholder="Enter Full nationality"
                      onChange={handleInputChange}
                      name="nationality"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleFormSubmit}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={toggleModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coaches;
