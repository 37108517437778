import React, { useEffect, useState } from "react";
import icon5 from "../assets/icon5.png";
import icon6 from "../assets/icon6.png";
import icon7 from "../assets/icon7.png";
import icon8 from "../assets/icon8.png";
import icon9 from "../assets/icon9.png";
import icon10 from "../assets/icon10.png";
import arrow1 from "../assets/arrow1.png";
import profile1 from "../assets/profile1.png";
import ground from "../assets/ground.png";
import { IoIosNotifications } from "react-icons/io";
import {
  IoPencilOutline,
  IoSearchOutline,
  IoSettings,
  IoTrashOutline,
} from "react-icons/io5";
import Sidebar from "./Sidebar";
import MobileSidebar from "./MobileSidebar";
import MobileTopNav from "./MobileTopNav";
import axios from "axios";
import ImageComponent from "./ImageComponent";

const HomePageComponent = () => {
  const [coachesData, setCoachesData] = useState([]);
  const [playersData, setPlayersData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [budgetsData, setBudgetsData] = useState([]);

  const fetchMembersData = async () => {
    try {
      const response = await axios.get("https://admin.smguld.com/api/v1/members");
      setCoachesData(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching members:", error);
      return [];
    }
  };

  const fetchPositionsData = async () => {
    try {
      const response = await axios.get(
        "https://admin.smguld.com/api/v1/positions"
      );
      console.log(response.data);
      setPositionData(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching positions:", error);
      return [];
    }
  };

  const fetchMembersDataPlayers = async () => {
    try {
      const response = await axios.get("https://admin.smguld.com/api/v1/members");
      const membersDataWithBudgets = response.data.map((member) => {
        const budget = budgetsData.find(
          (budget) => budget.member_id === member.id
        );
        return {
          ...member,
          budget: budget || {}, // If budget not found, provide an empty object
        };
      });
      setPlayersData(membersDataWithBudgets);
      return membersDataWithBudgets;
    } catch (error) {
      console.error("Error fetching members:", error);
      return [];
    }
  };

  const fetchMembersDataAll = async () => {
    try {
      const response = await axios.get("https://admin.smguld.com/api/v1/coaches");
      const membersDataWithBudgets = response.data.map((member) => {
        const budget = budgetsData.find(
          (budget) => budget.member_id === member.id
        );
        return {
          ...member,
          budget: budget || {}, // If budget not found, provide an empty object
        };
      });
      setCoachesData(membersDataWithBudgets);
      return membersDataWithBudgets;
    } catch (error) {
      console.error("Error fetching members:", error);
      return [];
    }
  };

  const getPositionLabel = (positionId) => {
    const position = positionData.find((pos) => pos.id === positionId);
    return position ? position.label : "Unknown Position";
  };

  const fetchBudgetsData = async () => {
    try {
      const response = await axios.get("https://admin.smguld.com/api/v1/budget");
      setBudgetsData(response.data);
      console.log("budgets ", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching budgets:", error);
      return [];
    }
  };

  useEffect(() => {
    fetchMembersDataAll();
    fetchPositionsData();
    fetchBudgetsData();
    fetchMembersDataPlayers();
  }, []);

  const calculateRowTotal = (player) => {
    return (
      player.salary +
      player.monthly_payment +
      player.bonus +
      (player.salary + player.bonus) * 0.32
    );
  };
  const calculateRowTotal1 = (player) => {
    return Math.floor(
      player.salary +
        player.monthly_payment +
        player.bonus +
        (player.salary + player.bonus) * 0.32
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3 bg-left-color px-4 py-3 height-side-bar main-sidebar-mobile">
          <div className="bg-left-color-text">
            <Sidebar />
          </div>
        </div>
        <div className="col-md-8 height-full-homepage">
          <div className="pt-4 row ">
            <div className="col-md-1 mt-2 text-center">
              <ImageComponent />
            </div>
            <div className="col-md-7">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-4 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} className="profile-img-nav" alt="" />
                  <p className="mt-1 profile-text-nav">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <div className="container ">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 bg-left-color-card">
                <div className="container pt-3">
                  <h5>My Teams Budget</h5>
                </div>
                <div className="row container py-3 ">
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon5} className="py-1" alt="" />
                    <h5>Budget</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon6} className="py-1" alt="" />
                    <h5>Extra Sponsor</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon7} className="py-1" alt="" />
                    <h5>Sales Income</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon8} className="py-1" alt="" />
                    <h5>Extra</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon9} className="py-1" alt="" />
                    <h5>Total Budget</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon10} className="py-1" alt="" />
                    <h5>Total Budget</h5>
                    <h5>$23,345</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            {/* <div className="col-md-1"></div> */}
            <div className="main-card mx-2 col-md-12 mx-5 bg-left-color-card">
              <div className="container row pb-3 pt-2">
                <table class="table-main">
                  <thead className="table-head-main">
                    <tr>
                      <th scope="col">Players</th>
                      <th scope="col">Position</th>
                      <th scope="col">Monthly S.</th>
                      <th scope="col">Monthly P.</th>
                      <th scope="col">Yearly Income</th>
                      <th scope="col">Bonus</th>
                      <th scope="col">Total S & B</th>
                      <th scope="col">SF</th>
                      <th scope="col">Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {playersData.map((player, index) => {
                      // Find the corresponding budget for the current player
                      const playerBudget = budgetsData.find(
                        (budget) => budget.member_id === player.id
                      );
                      console.log(playerBudget);
                      return (
                        <tr key={index}>
                          <td>{player.full_name}</td>
                          <td>{getPositionLabel(player.position_id)}</td>
                          <td>{player.salary}</td>
                          <td>{player.monthly_payment}</td>
                          <td>{player.salary * player.monthly_payment}</td>
                          <td>{player.bonus}</td>
                          <td>
                            {player.salary * player.monthly_payment +
                              player.bonus}
                          </td>
                          <td>
                            {Math.floor(
                              (parseInt(
                                player.salary * player.monthly_payment
                              ) +
                                parseInt(player.bonus)) *
                                0.32
                            )}
                          </td>
                          <td>{calculateRowTotal(player)}</td>
                          {/* Add more fields as needed */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {/* <div className="col-md-1"></div> */}
            <div className="main-card mx-2 col-md-12 mx-5 bg-left-color-card">
              <div className="container row pb-3 pt-2">
                <table class="table-main">
                  <thead className="table-head-main">
                    <tr>
                      <th scope="col">Coaches</th>
                      <th scope="col">Position</th>
                      <th scope="col">Monthly S.</th>
                      <th scope="col">Monthly P.</th>
                      <th scope="col">Yearly Income</th>
                      <th scope="col">Bonus</th>
                      <th scope="col">Total S & B</th>
                      <th scope="col">SF</th>
                      <th scope="col">Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coachesData.map((player, index) => (
                      <tr key={index}>
                        <td>{player.name}</td>
                        <td>{player.position}</td>
                        <td>{player.salary}</td>
                        <td>{player.monthly_payment}</td>
                        <td>{player.salary * player.monthly_payment}</td>
                        <td>{player.bonus}</td>
                        <td>
                          {player.salary * player.monthly_payment +
                            player.bonus}
                        </td>
                        <td>
                          {Math.floor(
                            (parseInt(player.salary * player.monthly_payment) +
                              parseInt(player.bonus)) *
                              0.32
                          )}
                        </td>
                        <td>{calculateRowTotal1(player)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="d-flex button-export col-12 mt-3">
            <button className="btn btn-secondary mx-2">Cancel</button>
            <button className="btn btn-primary">Save</button>
          </div>
        </div>

        {/* Mobile View */}

        <div className="col-md-11 height-1069">
          <div className="pt-4 row mobile-view-top-nav">
            <div className="col-md-2 mt-2 text-center row">
              <div className="col-md-6">
                <MobileSidebar />
              </div>
              <div className="col-md-6">
                <img src={arrow1} alt="" />
              </div>
            </div>
            <div className="col-md-7 mx-3">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-3 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} className="profile-img-nav" alt="" />
                  <p className="mt-1 profile-text-nav">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container mobile-view-top-nav">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 bg-left-color-card ">
                <div className="container pt-3">
                  <h5>My Teams Budget</h5>
                </div>
                <div className="row container py-3 ">
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon5} className="py-1" alt="" />
                    <h5>Budget</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon6} className="py-1" alt="" />
                    <h5>Extra Sponsor</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon7} className="py-1" alt="" />
                    <h5>Sales Income</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon8} className="py-1" alt="" />
                    <h5>Extra</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon9} className="py-1" alt="" />
                    <h5>Total Budget</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="col-md-2 bgc-cards text-center py-2">
                    <img src={icon10} className="py-1" alt="" />
                    <h5>Total Budget</h5>
                    <h5>$23,345</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mobile-top-nav">
            <div
              className="row"
              style={{ justifyContent: "center", marginLeft: "6px" }}
            >
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-4 bg-left-color-card">
                <div className="container pt-3">
                  <h5>My Teams Budget</h5>
                </div>
                <div className="py-3 d-flex">
                  <div className="card-mobile-1 bgc-cards text-center py-2">
                    <img src={icon5} className="py-1" alt="" />
                    <h5>abc</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="card-mobile-1 bgc-cards text-center py-2">
                    <img src={icon7} className="py-1" alt="" />
                    <h5>abc</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="card-mobile-1 bgc-cards text-center py-2">
                    <img src={icon9} className="py-1" alt="" />
                    <h5>abc</h5>
                    <h5>$23,345</h5>
                  </div>
                </div>
                <div className="py-3 d-flex">
                  <div className="card-mobile-1 bgc-cards text-center py-2">
                    <img src={icon7} className="py-1" alt="" />
                    <h5>abc</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="card-mobile-1 bgc-cards text-center py-2">
                    <img src={icon8} className="py-1" alt="" />
                    <h5>abc</h5>
                    <h5>$23,345</h5>
                  </div>
                  <div className="card-mobile-1 bgc-cards text-center py-2">
                    <img src={icon10} className="py-1" alt="" />
                    <h5>abc</h5>
                    <h5>$23,345</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3" style={{ width: "100%" }}>
            {/* <div className="col-md-1"></div> */}
            <div className="main-card table-margin col-md-12 mx-4 bg-left-color-card ">
              <table class="table-main w-100" style={{ overflowX: "auto" }}>
                <thead className="table-head-main">
                  <tr>
                    <th className="table-head" scope="col">
                      Players
                    </th>
                    <th className="table-head" scope="col">
                      Position
                    </th>
                    <th className="table-head" scope="col">
                      Monthly S.
                    </th>
                    <th className="table-head" scope="col">
                      Monthly P.
                    </th>
                    <th className="table-head" scope="col">
                      Yearly Income
                    </th>
                    <th className="table-head" scope="col">
                      Bonus
                    </th>
                    <th className="table-head" scope="col">
                      Total S & B
                    </th>
                    <th className="table-head" scope="col">
                      SF
                    </th>
                    <th scope="col" className="table-head">
                      Total Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {playersData.map((player, index) => {
                    // Find the corresponding budget for the current player
                    const playerBudget = budgetsData.find(
                      (budget) => budget.member_id === player.id
                    );
                    console.log(playerBudget);
                    return (
                      <tr key={index}>
                        <td>{player.full_name}</td>
                        <td>{getPositionLabel(player.position_id)}</td>
                        <td>{player.salary}</td>
                        <td>{player.monthly_payment}</td>
                        <td>{player.salary * player.monthly_payment}</td>
                        <td>{player.bonus}</td>
                        <td>
                          {player.salary * player.monthly_payment +
                            player.bonus}
                        </td>
                        <td>
                          {Math.floor(
                            (parseInt(player.salary * player.monthly_payment) +
                              parseInt(player.bonus)) *
                              0.32
                          )}
                        </td>
                        <td>{calculateRowTotal(player)}</td>
                        {/* Add more fields as needed */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-3" style={{ width: "100%" }}>
            {/* <div className="col-md-1"></div> */}
            <div className="main-card table-margin col-md-12 mx-4 bg-left-color-card ">
              <table class="table-main w-100" style={{ overflowX: "auto" }}>
                <thead className="table-head-main">
                  <tr>
                    <th className="table-head" scope="col">
                      Coaches
                    </th>
                    <th className="table-head" scope="col">
                      Position
                    </th>
                    <th className="table-head" scope="col">
                      Monthly S.
                    </th>
                    <th className="table-head" scope="col">
                      Monthly P.
                    </th>
                    <th className="table-head" scope="col">
                      Yearly Income
                    </th>
                    <th className="table-head" scope="col">
                      Bonus
                    </th>
                    <th className="table-head" scope="col">
                      Total S & B
                    </th>
                    <th className="table-head" scope="col">
                      SF
                    </th>
                    <th scope="col" className="table-head">
                      Total Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {coachesData.map((player, index) => (
                    <tr key={index}>
                      <td>{player.name}</td>
                      <td>{player.position}</td>
                      <td>{player.salary}</td>
                      <td>{player.monthly_payment}</td>
                      <td>{player.yearly_income}</td>
                      <td>{player.bonus}</td>
                      <td>{player.yearly_income + player.bonus}</td>
                      <td>
                        {Math.floor(
                          (parseInt(player.yearly_income) +
                            parseInt(player.bonus)) *
                            0.32
                        )}
                      </td>
                      <td>{calculateRowTotal1(player)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageComponent;
