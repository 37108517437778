import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom"; // Ensure correct import

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAdminPage, setShowAdminPage] = useState(false);

  // Get the navigate object from react-router-dom
  const navigate = useNavigate(); // Use navigate instead of history

  useEffect(() => {
    // Check if token exists and show admin page
    if (localStorage.getItem("nineToken")) {
      // Use navigate to redirect to the home page
      navigate("/");
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      // Validate input fields
      if (!email || !password) {
        showError("Please enter both email and password.");
        return;
      }

      // Make a POST request to your backend login API using HTTPS
      const response = await axios.post(
        "https://admin.smguld.com/api/v1/users/login",
        {
          email: email,
          password: password,
        }
      );

      // Use navigate to redirect to the home page
      navigate("/");
      // Handle the response as needed, e.g., store tokens, redirect, etc.
      const token = response.data.token;
      console.log("Login successful!");

      // Save token and expiration time in localStorage
      localStorage.setItem("nineToken", token);
      const expirationTime = new Date().getTime() + 60 * 60 * 1000; // One hour
      localStorage.setItem("nineTokenExpiration", expirationTime);

      // Set a timer to remove the token after one hour
      setTimeout(() => {
        localStorage.removeItem("nineToken");
        localStorage.removeItem("nineTokenExpiration");
        console.log("Token expired, removed from localStorage.");
      }, 60 * 60 * 1000); // One hour

      // Show success toast
      showSuccess("Login successful!");

      // Set the state to render the AdminPage component
      setShowAdminPage(true);
    } catch (error) {
      console.error("Error during login:", error);
      // Handle login error, e.g., show an error message
      showError("Invalid email or password. Please try again.");
    }
  };

  const showError = (message) => {
    toast.error(message, {
      autoClose: 3000,
    });
  };

  const showSuccess = (message) => {
    toast.success(message, {
      autoClose: 3000,
    });
  };

  return (
    <div>
      {!showAdminPage && (
        <div>
          {" "}
          <div className="container-xxl position-relative mt-5 d-flex p-0">
            {/* Sign In Start */}
            <div className="container-fluid">
              <div
                className="row h-100 align-items-center justify-content-center"
                style={{ minHeight: "100vh" }}
              >
                <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                  <div className="bg-light rounded p-4 p-sm-5 my-4 mx-3">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <h3 style={{ textDecoration: "none", color: "black" }}>
                        Nine
                      </h3>
                    </div>
                    <form onSubmit={handleSignIn}>
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">Email</label>
                      </div>
                      <div className="form-floating mb-4">
                        <input
                          type="password"
                          className="form-control"
                          id="floatingPassword"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingPassword">Password</label>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary py-3 w-100 mb-4"
                      >
                        Sign In
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Sign In End */}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
