import React, { useEffect, useState } from "react";
import arrow1 from "../assets/arrow1.png";
import profile1 from "../assets/profile1.png";
import { IoIosNotifications } from "react-icons/io";
import {
  IoSearchOutline,
  IoSettings,
  IoSearch,
  IoPencilOutline,
  IoTrashOutline,
} from "react-icons/io5";
import Sidebar from "./Sidebar";
import MobileTopNav from "./MobileTopNav";
import { FaPenClip } from "react-icons/fa6";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const [addUser, setAddUser] = useState([]);
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [editUserId, setEditUserId] = useState(null);
  const [editUserName, setEditUserName] = useState("");
  const [editUserEmail, setEditUserEmail] = useState("");
  const [editUserPassword, setEditUserPassword] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);

  // State variables for the form
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const fetchUserData = async () => {
    try {
      const response = await fetch("https://admin.smguld.com/api/v1/users");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setAddUser(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  const handleResultsPerPageChange = (e) => {
    setResultsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing results per page
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page when changing the search query
  };

  const indexOfLastUser = currentPage * resultsPerPage;
  const indexOfFirstUser = indexOfLastUser - resultsPerPage;

  const filteredUsers = addUser.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://admin.smguld.com/api/v1/users/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // If successful, you can handle the response as needed
      const data = await response.json();
      console.log("User created successfully:", data);
      // If there is an error, show an error toast notification
      // If successful, show a success toast notification
      toast.success("Email Verification has been send to you!", {
        autoClose: 3000, // Time to close the notification in milliseconds
      });
      // Optionally, you can reset the form after submission
      setFormData({
        name: "",
        email: "",
        password: "",
      });

      // Close the modal
      toggleModal();
    } catch (error) {
      // If there is an error, show an error toast notification
      toast.error("Error creating user. Please try again!", {
        autoClose: 3000,
      });
      console.error("Error creating user:", error);
    }
  };

  const options = Array.from(
    { length: Math.ceil(filteredUsers.length / 2) },
    (_, i) => i + 1
  );

  //deletion Method
  const handleDeleteUser = async (userId) => {
    try {
      const response = await fetch(
        `https://admin.smguld.com/api/v1/users/${userId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            // You may need to include authorization headers if required
          },
        }
      );

      // If successful, show a success toast notification
      toast.success("User Deleted successfully!", {
        autoClose: 3000, // Time to close the notification in milliseconds
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // If the deletion is successful, you may want to refresh the user list
      fetchUserData();
    } catch (error) {
      console.error("Delete error:", error);
      // If there is an error, show an error toast notification
      toast.error("Error Deleting user. Please try again!", {
        autoClose: 3000,
      });
    }
  };

  //update method
  const handleEditUser = (user) => {
    setEditUserId(user.id);
    setEditUserName(user.name);
    setEditUserEmail(user.email);
    setEditUserPassword(""); // Clear the password field
    setShowEditModal(true);
  };

  const handleUpdateUser = async () => {
    try {
      const response = await fetch(
        `https://admin.smguld.com/api/v1/users/${editUserId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: editUserName,
            email: editUserEmail,
            password: editUserPassword,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // If successful, show a success toast notification
      toast.success("User created successfully!", {
        autoClose: 3000, // Time to close the notification in milliseconds
      });
      // If successful, you can handle the response as needed
      const data = await response.json();
      console.log("User updated successfully:", data);

      // Close the modal and reset the state
      setShowEditModal(false);
      setEditUserId(null);
      setEditUserName("");
      setEditUserEmail("");
      setEditUserPassword("");
    } catch (error) {
      console.error("Error updating user:", error);
      // If successful, show a success toast notification
      toast.success("User created successfully!", {
        autoClose: 3000, // Time to close the notification in milliseconds
      });
    }
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3 bg-left-color px-4 py-3 height-side-bar main-sidebar-mobile">
          <div className="bg-left-color-text">
            <Sidebar />
          </div>
        </div>
        <div className="col-md-8 height-full">
          <div className="pt-4 row">
            <div className="col-md-1 mt-2 text-center">
              <img
                src={arrow1}
                alt=""
                onClick={handleBackClick}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="col-md-8">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-3 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 bg-left-color-card">
                <div className="container py-3 row">
                  <h5 className="col-md-6 mt-1">My Users</h5>
                  <h5 className="col-md-4">
                    <div class="input-group">
                      <input
                        className="form-control border"
                        type="search"
                        placeholder="Search by name"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <span class="input-group-append">
                        <button
                          class="btn btn-outline-secondary border-start-0 border-bottom-0  border ms-n5"
                          type="button"
                        >
                          <IoSearch />
                        </button>
                      </span>
                    </div>
                  </h5>
                  <div
                    className="btn btn-secondary col-md-2 pt-2 h-50"
                    onClick={toggleModal}
                  >
                    Add Users
                  </div>
                </div>

                <div className="container row pb-3">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col table-row-1">#</th>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedUsers.map(({ id, name, email }, index) => (
                        <tr key={index}>
                          <td>{indexOfFirstUser + index + 1}</td>
                          <td>{id}</td>
                          <td>{name}</td>
                          <td>{email}</td>
                          <td>
                            <button
                              className="btn mx-2 w-10 h-10 btn-danger"
                              onClick={() => handleDeleteUser(id)}
                            >
                              <IoTrashOutline color="white" />
                            </button>
                            <button
                              className="btn mx-2 w-10 h-10 btn-success"
                              onClick={() =>
                                handleEditUser({ id, name, email })
                              }
                            >
                              <FaPenClip color="white" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="container d-flex pb-3 justify-content-start">
                  Showing {paginatedUsers.length} of {filteredUsers.length}{" "}
                  results
                  <select
                    id="selection"
                    className="mx-2"
                    onChange={handleResultsPerPageChange}
                    value={resultsPerPage}
                  >
                    {options.map((option) => (
                      <option key={option} value={option * 2}>
                        {option * 2}
                      </option>
                    ))}
                  </select>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* mobile view */}
        <div className="col-md-12 height-full-add-player">
          <div className="pt-4 row mobile-view-top-nav">
            <div className="col-md-1 mt-2 text-center">
              <img src={arrow1} alt="" />
            </div>
            <div className="col-md-8">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-3 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container">
            <div
              className="row"
              style={{ justifyContent: "center", marginLeft: "10px" }}
            >
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-3 bg-left-color-card">
                <div className="container py-3 row">
                  <h5 className="col-md-6 mt-1">My Users</h5>
                  <h5 className="col-md-4">
                    <div class="input-group">
                      <input
                        class="form-control border"
                        type="search"
                        placeholder="john doe"
                        id="example-search-input"
                      />
                      <span class="input-group-append">
                        <button
                          class="btn btn-outline-secondary border-start-0 border-bottom-0  border ms-n5"
                          type="button"
                        >
                          <IoSearch />
                        </button>
                      </span>
                    </div>
                  </h5>
                  <div
                    className="btn btn-secondary col-md-2 pt-2 h-50"
                    onClick={toggleModal}
                  >
                    Add Users
                  </div>
                </div>

                <div
                  className="container row pb-3"
                  style={{ overflow: "auto" }}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col table-row-1">#</th>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedUsers.map(({ id, name, email }, index) => (
                        <tr key={index}>
                          <td>{indexOfFirstUser + index + 1}</td>
                          <td>{id}</td>
                          <td>{name}</td>
                          <td>{email}</td>
                          <td>
                            <button
                              className="btn mx-2 w-10 h-10 btn-danger"
                              onClick={() => handleDeleteUser(id)}
                            >
                              <IoTrashOutline color="white" />
                            </button>
                            <button
                              className="btn mx-2 w-10 h-10 btn-success"
                              onClick={() =>
                                handleEditUser({ id, name, email })
                              }
                            >
                              <FaPenClip color="white" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="container d-flex pb-3 justify-content-start">
                  Showing {paginatedUsers.length} of {addUser.length} results
                  <select
                    id="selection"
                    className="mx-2"
                    onChange={handleResultsPerPageChange}
                    value={resultsPerPage}
                  >
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                    {/* Add other options as needed */}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Bootstrap modal for adding teams */}
        <div
          className={`modal ${showModal ? "show" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ display: showModal ? "block" : "none" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create User</h5>
              </div>
              <div className="modal-body">
                {/* Add your form elements for adding a team here */}
                <form>
                  <div class="mb-3">
                    <label for="name" class="col-form-label">
                      Name:
                    </label>
                    <input
                      type="text"
                      class="form-control input-modal"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div class="mb-3">
                    <label for="email" class="col-form-label">
                      Email:
                    </label>
                    <input
                      class="form-control input-modal"
                      id="email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div class="mb-3">
                    <label for="password" class="col-form-label">
                      Password:
                    </label>
                    <input
                      class="form-control input-modal"
                      id="password"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleFormSubmit}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={toggleModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal ${showEditModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: showEditModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit User</h5>
            </div>
            <div className="modal-body">
              <form>
                <div class="mb-3">
                  <label for="name" class="col-form-label">
                    Name:
                  </label>
                  <input
                    type="text"
                    class="form-control input-modal"
                    id="name"
                    name="name"
                    value={editUserName}
                    onChange={(e) => setEditUserName(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="email" class="col-form-label">
                    Email:
                  </label>
                  <input
                    class="form-control input-modal"
                    id="email"
                    type="email"
                    name="email"
                    value={editUserEmail}
                    onChange={(e) => setEditUserEmail(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="password" class="col-form-label">
                    Password:
                  </label>
                  <input
                    class="form-control input-modal"
                    id="password"
                    type="password"
                    name="password"
                    value={editUserPassword}
                    onChange={(e) => setEditUserPassword(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleUpdateUser}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setShowEditModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
