import React, { useState } from "react";
import { FaBars } from "react-icons/fa6";
import { IoNotifications, IoSearch, IoSettings } from "react-icons/io5";
import profile1 from "../assets/profile1.png";
import arrow1 from "../assets/arrow1.png";

import img1 from "../assets/white_on_blue.png";
import icon1 from "../assets/icon1.png";
import icon2 from "../assets/icon2.png";
import icon3 from "../assets/icon3.png";
import icon4 from "../assets/icon4.png";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import ImageComponent from "./ImageComponent";

const MobileTopNav = () => {
  const [isSideNavOpen, setSideNavOpen] = useState(false);
  const [isTeamDropdownVisible, setTeamDropdownVisible] = useState(false);
  const [isPlayerDropdownVisible, setPlayerDropdownVisible] = useState(false);
  const [isSettingsDropdownVisible, setSettingsDropdownVisible] =
    useState(false);

  const openNav = () => {
    setSideNavOpen(true);
  };

  const closeNav = () => {
    setSideNavOpen(false);
  };

  const toggleTeamDropdown = () => {
    setTeamDropdownVisible(!isTeamDropdownVisible);
  };

  const togglePlayerDropdown = () => {
    setPlayerDropdownVisible(!isPlayerDropdownVisible);
  };

  const toggleSettingsDropdown = () => {
    setSettingsDropdownVisible(!isSettingsDropdownVisible);
  };

  return (
    <div>
      {/* Side Navigation */}
      <div id="mySidenav" className={`sidenav ${isSideNavOpen ? "open" : ""} `}>
        <div className="bg-left-color-text">
          <div
            className="container px-5 py-3 height-side-bar-inner"
            style={{ overflowY: "auto", height: "100%" }}
          >
            <button className="closebtn" onClick={closeNav}>
              &times;
            </button>
            <img className="mt-2" src={img1} alt="image logo" />
            <Link to="/" className="text-decor row mt-5">
              <div className="col-md-2">
                <img src={icon1} alt="" />
              </div>
              <div className="col-md-9 mt-1 dashboard-link">Dashboard</div>
            </Link>
            <Link className="text-decor-1 row mt-3">
              <div className="col-md-2">
                <img src={icon2} alt="" />
              </div>
              <div
                className="col-md-9 mt-1 dashboard-link"
                onClick={toggleTeamDropdown}
              >
                Team Management{" "}
                {isTeamDropdownVisible ? <IoIosArrowDown /> : null}
              </div>
            </Link>
            {isTeamDropdownVisible && (
              <div className="dropdown-content">
                <Link to="/add-team">My Teams</Link>
              </div>
            )}
            <Link
              className={`text-decor-2 row mt-3 ${
                isPlayerDropdownVisible ? "active" : ""
              }`}
              onClick={togglePlayerDropdown}
            >
              <div className="col-md-2">
                <img src={icon3} alt="" />
              </div>
              <div className="col-md-9 mt-1 dashboard-link">
                Player Management{" "}
                {isPlayerDropdownVisible ? <IoIosArrowDown /> : null}
              </div>
            </Link>
            {isPlayerDropdownVisible && (
              <div className="dropdown-content">
                <Link to="/my-player">My Player List</Link>
                <Link to="/add-player">Add Player</Link>
              </div>
            )}
            <Link
              className={`text-decor-3 row mt-3 ${
                isSettingsDropdownVisible ? "active" : ""
              }`}
              onClick={toggleSettingsDropdown}
            >
              <div className="col-md-2">
                <img src={icon4} alt="" />
              </div>
              <div className="col-md-9 mt-1 dashboard-link">
                Settings {isSettingsDropdownVisible ? <IoIosArrowDown /> : null}
              </div>
            </Link>
            {isSettingsDropdownVisible && (
              <div className="dropdown-content">
                <Link to="/general-settings">General Settings</Link>
                <Link to="/coaches">Coaches</Link>
                <Link to="/add-managers">Add Managers</Link>
                <Link to="/position-list">Positon List</Link>
                <Link to="/add-user">User List</Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Top Navigation */}
      <div className="container mobile-top-nav">
        <div className="pt-4 d-flex pb-4">
          <div className="part-1">
            <div className="border-mobile-toggle" onClick={openNav}>
              <FaBars style={{ width: "20px", height: "20px" }} />
            </div>
          </div>
          <div className="part-2">
            <div className="d-flex">
              <div className="mt-1 mx-2">
                <IoSearch />
              </div>
              <div className="mt-1 mx-2">
                <IoNotifications />
              </div>
              <div className="profile-top-nav-mobile d-flex ">
                <img src={profile1} className="profile-img-nav mx-1" alt="" />
                <p className="mt-1 profile-text-nav">Pank</p>
                <p className="mt-1 mx-1">|</p>
                <IoSettings className="mt-2 mx-1 icon-40" />
              </div>
            </div>
          </div>
        </div>
        <div className=" d-flex pb-4">
          <div className="part-1-lower">
            <div className="col-md-1 mt-2 text-center">
              <ImageComponent />
            </div>
          </div>
          <div className="part-2 mx-2">
            <h4 className="mb-0 fw-bold">Good Evening, Pank!</h4>
            <p>Today July 23, 2023 | 7:23 PM</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileTopNav;
