import React, { useEffect, useState } from "react";
import arrow1 from "../assets/arrow1.png";
import profile1 from "../assets/profile1.png";
import { IoIosNotifications } from "react-icons/io";
import { IoSearchOutline, IoSettings } from "react-icons/io5";
import Sidebar from "./Sidebar";
import { AiOutlineCloudUpload } from "react-icons/ai";
import MobileTopNav from "./MobileTopNav";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AddPlayer = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    position_id: "",
    salary: "",
    age: "",
    social_security: "",
    bonus: "",
    nationality: "",
    monthly_payment: "",
    team_id: "",
    image: null,
  });

  //get Position
  const [positions, setPositions] = useState([]);
  //get Teams
  const [teams, setTeams] = useState([]);

  const [filePreview, setFilePreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    // Fetch positions from your API endpoint
    const fetchPositions = async () => {
      try {
        const response = await axios.get(
          "https://admin.smguld.com/api/v1/positions"
        );
        setPositions(response.data); // Assuming response.data is an array of positions
      } catch (error) {
        console.error("Error fetching positions:", error.message);
      }
    };
    //fetch Teams
    const fetchTeams = async () => {
      try {
        const response = await axios.get("https://admin.smguld.com/api/v1/teams");
        setTeams(response.data); // Assuming response.data is an array of teams
      } catch (error) {
        console.error("Error fetching teams:", error.message);
      }
    };

    fetchTeams();

    fetchPositions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Convert specific fields to integers if necessary
    const intValue = [
      "position_id",
      "team_id",
      "salary",
      "age",
      "monthly_payment",
      "social_security",
      "bonus",
    ].includes(name)
      ? parseInt(value, 10)
      : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: intValue,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      setSelectedFile(file);

      // Update formData with the selected image
      console.log(file.name);
      setFormData({
        ...formData,
        image: file.name, // Assuming you want to store the image name in the formData
      });

      // Preview the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setFilePreview(null);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      // Prepare form data
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("full_name", formData.full_name);
      formDataToSubmit.append("age", formData.age);
      formDataToSubmit.append("position_id", formData.position_id);
      formDataToSubmit.append("salary", formData.salary);
      formDataToSubmit.append("nationality", formData.nationality);
      formDataToSubmit.append("monthly_payment", formData.monthly_payment);
      formDataToSubmit.append("bonus", formData.bonus);
      formDataToSubmit.append("team_id", formData.team_id);
      formDataToSubmit.append("image", selectedFile);

      const response = await axios.post(
        "https://admin.smguld.com/api/v1/members",
        formDataToSubmit
      );

      if (response.status !== 201) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // If successful, show a success toast notification
      toast.success("User created successfully!", {
        autoClose: 3000, // Time to close the notification in milliseconds
      });

      // Handle other logic as needed
      setFormData([]);
    } catch (error) {
      console.error("Error creating user:", error);
      // If there is an error, show an error toast notification
      toast.error("Error creating user. Please try again!", {
        autoClose: 3000,
      });
    }
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3 bg-left-color px-4 py-3 height-side-bar main-sidebar-mobile">
          <div className="bg-left-color-text">
            <Sidebar />
          </div>
        </div>
        <div className="col-md-8 height-full ">
          <div className="pt-4 row mobile-view-top-nav">
            <div className="col-md-1 mt-2 text-center">
              <img
                src={arrow1}
                alt=""
                onClick={handleBackClick}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="col-md-7">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-4 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-11 mx-3 bg-left-color-card">
                <div className="container py-3">
                  <h5 className="col-md-7 mt-1">My Player Information</h5>
                </div>
              </div>
            </div>

            <div className="container my-5">
              <h4>Add Players</h4>
              <div className="mb-3">
                <label htmlFor="full_name" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="full_name"
                  value={formData.full_name}
                  placeholder="Enter Full Name"
                  onChange={handleInputChange}
                  name="full_name"
                />
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="position_id" className="form-label">
                      Position
                    </label>
                    <br />
                    <select
                      name="position_id"
                      id="position_id"
                      className="position"
                      value={formData.position_id}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Position</option>
                      {positions.map((position) => (
                        <option key={position.id} value={position.id}>
                          {position.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="salary" className="form-label">
                      Salary
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="salary"
                      name="salary"
                      placeholder="Enter Salary"
                      value={formData.salary}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="age" className="form-label">
                      Age
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="age"
                      name="age"
                      placeholder="Enter age"
                      value={formData.age}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <label htmlFor="monthly_payment" className="form-label">
                      Monthly Payment
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="monthly_payment"
                      name="monthly_payment"
                      placeholder="Enter monthly payment"
                      value={formData.monthly_payment}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="bonus" className="form-label">
                      Bonus
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bonus"
                      name="bonus"
                      placeholder="Enter Bonus"
                      value={formData.bonus}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="social_security" className="form-label">
                      Social Security
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="social_security"
                      name="social_security"
                      placeholder="Enter social_security"
                      value={formData.social_security}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-6 ">
                    <label htmlFor="nationality" className="form-label">
                      Nationality
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nationality"
                      name="nationality"
                      placeholder="Enter Nationality"
                      value={formData.nationality}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="team_id" className="form-label">
                      Team
                    </label>
                    <select
                      name="team_id"
                      id="team_id"
                      className="position"
                      value={formData.team_id}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Team</option>
                      {teams.map((team) => (
                        <option key={team.id} value={team.id}>
                          {team.team_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <label htmlFor="profile_picture" className="form-label">
                Profile Picture
              </label>
              <div className="mb-3">
                <label htmlFor="image" className="form-label">
                  Image
                </label>
                <input
                  type="file"
                  required
                  className="form-control"
                  id="image"
                  onChange={handleImageChange}
                  name="image"
                />
                {filePreview && (
                  <img
                    src={filePreview}
                    alt="Preview"
                    style={{ width: "50px", height: "50px" }}
                  />
                )}
              </div>
              <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-primary" onClick={handleFormSubmit}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 height-full-add-player ">
          <div className="pt-4 row mobile-view-top-nav">
            <div className="col-md-1 mt-2 text-center">
              <img src={arrow1} alt="" />
            </div>
            <div className="col-md-7">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-4 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-11 mx-3 bg-left-color-card">
                <div className="container py-3">
                  <h5 className="col-md-7 mt-1">My Player Information</h5>
                </div>
              </div>
            </div>

            <div className="container my-5">
              <h4>Add Players</h4>
              <div className="mb-3">
                <label htmlFor="full_name" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="full_name"
                  value={formData.full_name}
                  placeholder="Enter Full Name"
                  onChange={handleInputChange}
                  name="full_name"
                />
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="position_id" className="form-label">
                      Position
                    </label>
                    <br />
                    <select
                      name="position_id"
                      id="position_id"
                      className="position"
                      value={formData.position_id}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Position</option>
                      {positions.map((position) => (
                        <option key={position.id} value={position.id}>
                          {position.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="salary" className="form-label">
                      Salary
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="salary"
                      name="salary"
                      placeholder="Enter Salary"
                      value={formData.salary}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="age" className="form-label">
                      Age
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="age"
                      name="age"
                      placeholder="Enter age"
                      value={formData.age}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <label htmlFor="monthly_payment" className="form-label">
                      Monthly Payment
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="monthly_payment"
                      name="monthly_payment"
                      placeholder="Enter monthly payment"
                      value={formData.monthly_payment}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="bonus" className="form-label">
                      Bonus
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bonus"
                      name="bonus"
                      placeholder="Enter Bonus"
                      value={formData.bonus}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="social_security" className="form-label">
                      Social Security
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="social_security"
                      name="social_security"
                      placeholder="Enter social_security"
                      value={formData.social_security}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-6 ">
                    <label htmlFor="nationality" className="form-label">
                      Nationality
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nationality"
                      name="nationality"
                      placeholder="Enter Nationality"
                      value={formData.nationality}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="team_id" className="form-label">
                      Team
                    </label>
                    <select
                      name="team_id"
                      id="team_id"
                      className="position"
                      value={formData.team_id}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Team</option>
                      {teams.map((team) => (
                        <option key={team.id} value={team.id}>
                          {team.team_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <label htmlFor="profile_picture" className="form-label">
                Profile Picture
              </label>
              <div className="mb-3">
                <label htmlFor="image" className="form-label">
                  Image
                </label>
                <input
                  type="file"
                  required
                  className="form-control"
                  id="image"
                  onChange={handleImageChange}
                  name="image"
                />
                {filePreview && (
                  <img
                    src={filePreview}
                    alt="Preview"
                    style={{ width: "50px", height: "50px" }}
                  />
                )}
              </div>
              <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-primary" onClick={handleFormSubmit}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPlayer;
