import React from "react";
import { FaPenClip } from "react-icons/fa6";
import { IoTrashOutline } from "react-icons/io5";
import coachDummy from "../assets/coachDummy.png";

const CoachesTable = ({
  paginatedUsers,
  indexOfFirstUser,
  handleDeleteUser,
  handleEditUser,
}) => {
  const renderImage = (image) => {
    const base64Image = btoa(
      String.fromCharCode.apply(null, new Uint8Array(image.data))
    );
    const dataUrl = `data:image/png;base64,${base64Image}`;
    if (image && image.length > 0) {
      // Convert Uint8Array to base64
      return (
        <img
          src={dataUrl}
          alt="Player"
          style={{ width: "50px", height: "50px" }}
        />
      );
    }
    return coachDummy;
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col table-row-1">#</th>
          <th scope="col">ID</th>
          <th scope="col">Name</th>
          <th scope="col">Age</th>
          <th scope="col">Positions</th>
          <th scope="col">Image</th>
          <th scope="col">Salary</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {paginatedUsers.map((player, index) => (
          <tr key={index}>
            <td>{indexOfFirstUser + index + 1}</td>
            <td>{player.id}</td>
            <td>{player.name}</td>
            <td>{player.age}</td>
            <td>{player.position}</td>
            <td>
              <img
                className="dummyCoach"
                src={`data:image/png;base64,${player.image}`}
                alt={player.name}
                onError={(e) => {
                  e.target.src =
                    "https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png"; // replace with your dummy avatar image path
                }}
              />
            </td>
            <td>{player.salary}</td>
            <td>
              <button
                className="btn mx-2 w-10 h-10 btn-danger"
                onClick={() => handleDeleteUser(player.id)}
              >
                <IoTrashOutline color="white" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CoachesTable;
