import React, { useEffect, useState } from "react";
import arrow1 from "../assets/arrow1.png";
import profile1 from "../assets/profile1.png";
import { IoIosNotifications } from "react-icons/io";
import {
  IoSearch,
  IoSearchOutline,
  IoSettings,
  IoPencilOutline,
  IoPersonOutline,
  IoTrashOutline,
  IoClose,
} from "react-icons/io5";
import Sidebar from "./Sidebar";
import MobileTopNav from "./MobileTopNav";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaPencil } from "react-icons/fa6";
import DummyImage from "../assets/coachDummy.png";
import { toast } from "react-toastify";
import ImageComponent from "./ImageComponent";

const AddTeam = () => {
  const [editMode, setEditMode] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [teamsData, setTeamsData] = useState([]);
  const [teamImage, setTeamImage] = useState(null);
  const [users, setUsers] = useState([]);
  const [resultsPerPage, setResultsPerPage] = useState(5); // Default value

  const [formData, setFormData] = useState({
    team_name: "",
    budget: "",
    extraSponsor: "",
    salesIncome: "",
    extra: "",
    totalBudget: "",
    user_id: 11,
  });

  const toggleModal = (teamId = null) => {
    setEditMode(!teamId);
    setSelectedTeamId(teamId);
    setShowModal(!showModal);
  };
  const toggleModalEdit = (teamId = null) => {
    setEditMode(!!teamId);
    setSelectedTeamId(teamId);
    setShowModal(!showModal);
  };

  useEffect(() => {
    // Fetch teams data when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get("https://admin.smguld.com/api/v1/teams");
        setTeamsData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (editMode && selectedTeamId) {
      const fetchTeamData = async () => {
        try {
          const response = await axios.get(
            `https://admin.smguld.com/api/v1/teams/${selectedTeamId}`
          );
          setFormData(response.data);
        } catch (error) {
          console.error("Error fetching team data:", error);
        }
      };

      fetchTeamData();
    }
  }, [editMode, selectedTeamId]);

  // Add this useEffect to fetch users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("https://admin.smguld.com/api/v1/users");
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const startIdx = 0; // Set the starting index of the displayed teams
  const endIdx = startIdx + resultsPerPage; // Set the ending index of the displayed teams
  const displayedTeams = teamsData.slice(startIdx, endIdx);

  const handleResultsPerPageChange = (e) => {
    setResultsPerPage(parseInt(e.target.value, 10));
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserSelect = (e) => {
    const selectedUserId = e.target.value;
    setFormData({
      ...formData,
      user_id: selectedUserId,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setTeamImage(file);
  };

  const handleFormSubmit = async () => {
    try {
      if (editMode) {
        const response = await axios.patch(
          `https://admin.smguld.com/api/v1/teams/team/${selectedTeamId}`,
          formData
        );

        if (response.status === 200) {
          console.log("Team updated successfully:", response.data);
          // If successful, show a success toast notification
          toast.success("Updated successfully!", {
            autoClose: 3000, // Time to close the notification in milliseconds
          });
          toggleModal(); // Close the modal after editing
        } else {
          toast.error("Error updating Coaches. Please try again!", {
            autoClose: 3000,
          });
          console.error("Failed to update team");
        }
      } else {
        const formDataWithImage = new FormData();
        formDataWithImage.append("team_name", formData.team_name);
        formDataWithImage.append("budget", formData.budget);
        formDataWithImage.append("user_id", formData.user_id);
        formDataWithImage.append("extraSponsor", formData.extraSponsor);
        formDataWithImage.append("salesIncome", formData.salesIncome);
        formDataWithImage.append("extra", formData.extra);
        formDataWithImage.append("totalBudget", formData.totalBudget);
        formDataWithImage.append("image", teamImage);
        console.log(formDataWithImage);

        const response = await axios.post(
          "https://admin.smguld.com/api/v1/teams",
          formDataWithImage
        );
        // If successful, show a success toast notification
        toast.success("Created successfully!", {
          autoClose: 3000, // Time to close the notification in milliseconds
        });

        if (response.status === 200) {
          toggleModal();
          console.log("Form data submitted:", response.data);
        } else {
          console.error("Failed to add team");
        }
      }
    } catch (error) {
      toast.error("Error. Please try again!", {
        autoClose: 3000,
      });
      console.error("Error occurred while adding/updating team", error);
    }
  };

  //delete Method
  const handleDelete = async (teamId) => {
    try {
      const response = await axios.delete(
        `https://admin.smguld.com/api/v1/teams/${teamId}`
      );

      if (response.status === 200) {
        // If successful, show a success toast notification
        toast.success("Deleted successfully!", {
          autoClose: 3000, // Time to close the notification in milliseconds
        });
        // Fetch the updated teams data after deletion
        const updatedTeams = await axios.get(
          "https://admin.smguld.com/api/v1/teams"
        );

        setTeamsData(updatedTeams.data);
      } else {
        console.error("Failed to delete team");
      }
    } catch (error) {
      toast.error("Error. Please try again!", {
        autoClose: 3000,
      });
      console.error("Error occurred while deleting team", error);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3 bg-left-color px-4 py-3 height-side-bar main-sidebar-mobile">
          <div className="bg-left-color-text">
            <Sidebar />
          </div>
        </div>
        <div className="col-md-8 height-full">
          <div className="pt-4 row mobile-view-top-nav">
            <div className="col-md-1 mt-2 text-center">
              <ImageComponent />
            </div>
            <div className="col-md-7">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-4 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-2 bg-left-color-card">
                <div className="container">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="main-card col-md-11 bg-left-color-card">
                      <div className="container py-3 row">
                        <h5 className="col-md-6 mt-1">My Teams</h5>
                        <h5 className="col-md-4">
                          <div class="input-group">
                            <input
                              class="form-control border"
                              type="search"
                              placeholder="john doe"
                              id="example-search-input"
                            />
                            <span class="input-group-append">
                              <button
                                class="btn btn-outline-secondary border-start-0 border-bottom-0  border ms-n5"
                                type="button"
                              >
                                <IoSearch />
                              </button>
                            </span>
                          </div>
                        </h5>

                        <div
                          className="btn btn-secondary col-md-2 pt-2 h-50"
                          onClick={toggleModal}
                        >
                          Add Team
                        </div>
                      </div>

                      <div className="container row pb-3">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col table-row-1">#</th>
                              <th scope="col">ID</th>
                              <th scope="col">Name</th>
                              <th scope="col">Images</th>
                              <th scope="col">Budget</th>
                              <th scope="col">Actions</th>
                              {/* <th scope="col">Action</th> */}
                            </tr>
                          </thead>

                          {/* Table body with dummy data */}
                          <tbody>
                            {displayedTeams.map((team, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{team.id}</td>
                                <td>{team.team_name}</td>
                                <td>
                                  <img
                                    src={
                                      team.image
                                        ? `data:image/png;base64,${team.image}`
                                        : DummyImage
                                    }
                                    alt={team.team_name}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "30px",
                                    }}
                                  />
                                </td>
                                <td>{team.budget}</td>
                                <td>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => toggleModalEdit(team.id)}
                                  >
                                    <FaPencil color="white" />
                                  </button>
                                  <Link to={`/budget/${team.id}`}>
                                    <button className="btn btn-warning mx-2">
                                      <IoPersonOutline color="white" />
                                    </button>
                                  </Link>
                                  <Link to={`/team-board/${team.id}`}>
                                    <button className="btn btn-info mx-2">
                                      <IoPersonOutline color="white" />
                                    </button>
                                  </Link>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => handleDelete(team.id)}
                                  >
                                    <IoTrashOutline color="white" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="container d-flex pb-3 justify-content-start">
                        {`Showing ${Math.min(
                          teamsData.length,
                          resultsPerPage
                        )} of ${teamsData.length} results`}
                        <select
                          id="selection"
                          className="mx-2"
                          onChange={handleResultsPerPageChange}
                          value={resultsPerPage}
                        >
                          {[3, 5, 10, 20].map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile View */}

        <div className="col-md-12 height-full-add-player">
          <div className="pt-4 row mobile-view-top-nav">
            <div className="col-md-1 mt-2 text-center">
              <img src={arrow1} alt="" />
            </div>
            <div className="col-md-7">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-4 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-2 bg-left-color-card">
                <div className="container">
                  <div className="row">
                    <div className="main-card col-md-11  bg-left-color-card">
                      <div className="container py-3 row">
                        <h5 className="col-md-6 mt-1">My Teams</h5>
                        <h5 className="col-md-4">
                          <div class="input-group">
                            <input
                              class="form-control border"
                              type="search"
                              placeholder="john doe"
                              id="example-search-input"
                            />
                            <span class="input-group-append">
                              <button
                                class="btn btn-outline-secondary border-start-0 border-bottom-0  border ms-n5"
                                type="button"
                              >
                                <IoSearch />
                              </button>
                            </span>
                          </div>
                        </h5>

                        <div
                          className="btn btn-secondary col-md-2 pt-2 h-50"
                          onClick={toggleModal}
                        >
                          Add Team
                        </div>
                      </div>

                      <div
                        className="container row pb-3"
                        style={{ overflow: "auto" }}
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col table-row-1">#</th>
                              <th scope="col">ID</th>
                              <th scope="col">Name</th>
                              <th scope="col">Images</th>
                              <th scope="col">Budget</th>
                              <th scope="col">Actions</th>
                              {/* <th scope="col">Action</th> */}
                            </tr>
                          </thead>

                          {/* Table body with dummy data */}
                          <tbody>
                            {displayedTeams.map((team, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{team.id}</td>
                                <td>{team.team_name}</td>
                                <td>
                                  <img
                                    src={
                                      team.image
                                        ? `data:image/png;base64,${team.image}`
                                        : DummyImage
                                    }
                                    alt={team.team_name}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "30px",
                                    }}
                                  />
                                </td>
                                <td>{team.budget}</td>
                                <td className="d-flex">
                                  <button
                                    className="btn btn-success"
                                    onClick={() => toggleModalEdit(team.id)}
                                  >
                                    <FaPencil color="white" />
                                  </button>
                                  <Link to={`/budget/${team.id}`}>
                                    <button className="btn btn-warning mx-2">
                                      <IoPersonOutline color="white" />
                                    </button>
                                  </Link>
                                  <Link to={`/team-board/${team.id}`}>
                                    <button className="btn btn-info mx-2">
                                      <IoPersonOutline color="white" />
                                    </button>
                                  </Link>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => handleDelete(team.id)}
                                  >
                                    <IoTrashOutline color="white" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="container d-flex pb-3 justify-content-start">
                        {`Showing ${Math.min(
                          teamsData.length,
                          resultsPerPage
                        )} of ${teamsData.length} results`}
                        <select
                          id="selection"
                          className="mx-2"
                          onChange={handleResultsPerPageChange}
                          value={resultsPerPage}
                        >
                          {[3, 5, 10, 20].map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap modal for adding teams */}
      <div
        className={`modal ${showModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Team Information</h5>
            </div>
            <div className="modal-body">
              {/* Add your form elements for adding a team here */}
              <form>
                <div class="mb-3">
                  <label for="team_name" class="col-form-label">
                    Add Teams:
                  </label>
                  <input
                    type="text"
                    class="form-control input-modal"
                    id="team_name"
                    name="team_name"
                    value={formData.team_name} // Set the value here
                    onChange={handleInputChange}
                  />
                </div>
                <div class="mb-3">
                  <label for="budget" class="col-form-label">
                    Budget:
                  </label>
                  <input
                    type="text"
                    class="form-control input-modal"
                    id="budget"
                    name="budget"
                    value={formData.budget} // Set the value here
                    onChange={handleInputChange}
                  />
                </div>
                <div class="mb-3">
                  <label htmlFor="extraSponsor" class="col-form-label">
                    Extra Sponsor:
                  </label>
                  <input
                    type="text"
                    class="form-control input-modal"
                    id="extraSponsor"
                    name="extraSponsor"
                    value={formData.extraSponsor} // Set the value here
                    onChange={handleInputChange}
                  />
                </div>
                <div class="mb-3">
                  <label htmlFor="salesIncome" class="col-form-label">
                  Sales Income:
                  </label>
                  <input
                    type="text"
                    class="form-control input-modal"
                    id="salesIncome"
                    name="salesIncome"
                    value={formData.salesIncome} // Set the value here
                    onChange={handleInputChange}
                  />
                </div>
                <div class="mb-3">
                  <label htmlFor="extra" class="col-form-label">
                  Extra:
                  </label>
                  <input
                    type="text"
                    class="form-control input-modal"
                    id="extra"
                    name="extra"
                    value={formData.extra} // Set the value here
                    onChange={handleInputChange}
                  />
                </div>
                <div class="mb-3">
                  <label htmlFor="totalBudget" class="col-form-label">
                  Total Budget:
                  </label>
                  <input
                    type="text"
                    class="form-control input-modal"
                    id="totalBudget"
                    name="totalBudget"
                    value={formData.totalBudget} // Set the value here
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="user" className="col-form-label">
                    Select User:
                  </label>
                  <select
                    className="form-control"
                    id="user"
                    onChange={handleUserSelect}
                    value={formData.user_id} // Set the value here
                  >
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="image" className="col-form-label">
                    Team Image:
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleFormSubmit}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={toggleModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTeam;
