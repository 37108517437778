// styles.js
import styled from "styled-components";

export const getMarginValues = (index) => {
  const marginTopIndexs =
    index === 0
      ? "10.5rem"
      : index === 1
      ? "99px"
      : index === 2
      ? "228px"
      : index === 3
      ? "33px"
      : index === 4
      ? "307px"
      : index === 5
      ? "33px"
      : index === 6
      ? "99px"
      : index === 7
      ? "200px"
      : index === 8
      ? "296px"
      : index === 9
      ? "13rem"
      : index === 10
      ? "7rem"
      : "0";
  const marginLeftIndexs =
    index === 0
      ? "9rem"
      : index === 1
      ? "43px"
      : index === 2
      ? "-43px"
      : index === 3
      ? "-4px"
      : index === 4
      ? "-46px"
      : index === 5
      ? "43px"
      : index === 6
      ? "-60px"
      : index === 7
      ? "-50px"
      : index === 8
      ? "-46px"
      : index === 9
      ? "8rem"
      : index === 10
      ? "-3rem"
      : "0";

  const margintop1410 =
    index === 0
      ? "10.5rem"
      : index === 1
      ? "6rem"
      : index === 2
      ? "14rem"
      : index === 3
      ? "2.35rem"
      : index === 4
      ? "17.5rem"
      : index === 5
      ? "2rem"
      : index === 6
      ? "6rem"
      : index === 7
      ? "11rem"
      : index === 8
      ? "17rem"
      : index === 9
      ? "7rem"
      : index === 10
      ? "12rem"
      : "0";
  const marginleft1410 =
    index === 0
      ? "8.5rem"
      : index === 1
      ? "3rem"
      : index === 2
      ? "-2rem"
      : index === 3
      ? "1rem"
      : index === 4
      ? "-1rem"
      : index === 5
      ? "2rem"
      : index === 6
      ? "-3rem"
      : index === 7
      ? "-2rem"
      : index === 8
      ? "-1rem"
      : index === 9
      ? "5rem"
      : index === 10
      ? "-2rem"
      : "0";
  const margintop1260 =
    index === 0
      ? "9.5rem"
      : index === 1
      ? "6rem"
      : index === 2
      ? "13rem"
      : index === 3
      ? "2.35rem"
      : index === 4
      ? "16rem"
      : index === 5
      ? "2rem"
      : index === 6
      ? "6rem"
      : index === 7
      ? "11rem"
      : index === 8
      ? "16rem"
      : index === 9
      ? "7rem"
      : index === 10
      ? "12rem"
      : "0";
  const marginleft1260 =
    index === 0
      ? "7.5rem"
      : index === 1
      ? "3rem"
      : index === 2
      ? "-2rem"
      : index === 3
      ? "1rem"
      : index === 4
      ? "-2rem"
      : index === 5
      ? "2rem"
      : index === 6
      ? "-3rem"
      : index === 7
      ? "-2rem"
      : index === 8
      ? "-1rem"
      : index === 9
      ? "5rem"
      : index === 10
      ? "-2rem"
      : "0";
  const margintop1170 =
    index === 0
      ? "8.5rem"
      : index === 1
      ? "4rem"
      : index === 2
      ? "12.5rem"
      : index === 3
      ? "2.35rem"
      : index === 4
      ? "14rem"
      : index === 5
      ? "2rem"
      : index === 6
      ? "6rem"
      : index === 7
      ? "10rem"
      : index === 8
      ? "14rem"
      : index === 9
      ? "6rem"
      : index === 10
      ? "11rem"
      : "0";
  const marginleft1170 =
    index === 0
      ? "6.75rem"
      : index === 1
      ? "2rem"
      : index === 2
      ? "-2rem"
      : index === 3
      ? "1rem"
      : index === 4
      ? "-2rem"
      : index === 5
      ? "3rem"
      : index === 6
      ? "-3rem"
      : index === 7
      ? "-2rem"
      : index === 8
      ? "-1rem"
      : index === 9
      ? "4rem"
      : index === 10
      ? "-2rem"
      : "0";
  const margintop1067 =
    index === 0
      ? "10.5rem"
      : index === 1
      ? "6rem"
      : index === 2
      ? "14.5rem"
      : index === 3
      ? "2.35rem"
      : index === 4
      ? "17rem"
      : index === 5
      ? "2rem"
      : index === 6
      ? "7rem"
      : index === 7
      ? "12rem"
      : index === 8
      ? "17rem"
      : index === 9
      ? "7em"
      : index === 10
      ? "12rem"
      : "0";
  const marginleft1067 =
    index === 0
      ? "8.75rem"
      : index === 1
      ? "3rem"
      : index === 2
      ? "-2rem"
      : index === 3
      ? "1rem"
      : index === 4
      ? "-2rem"
      : index === 5
      ? "3rem"
      : index === 6
      ? "-3rem"
      : index === 7
      ? "-2rem"
      : index === 8
      ? "-1rem"
      : index === 9
      ? "6rem"
      : index === 10
      ? "-2rem"
      : "0";
  const margintop992 =
    index === 0
      ? "7.5rem"
      : index === 1
      ? "4rem"
      : index === 2
      ? "10.5rem"
      : index === 3
      ? "2.35rem"
      : index === 4
      ? "13rem"
      : index === 5
      ? "2rem"
      : index === 6
      ? "6rem"
      : index === 7
      ? "10rem"
      : index === 8
      ? "13rem"
      : index === 9
      ? "5rem"
      : index === 10
      ? "10rem"
      : "0";
  const marginleft992 =
    index === 0
      ? "5.75rem"
      : index === 1
      ? "2rem"
      : index === 2
      ? "-2rem"
      : index === 3
      ? "1rem"
      : index === 4
      ? "-2rem"
      : index === 5
      ? "2rem"
      : index === 6
      ? "-3rem"
      : index === 7
      ? "-2rem"
      : index === 8
      ? "-1rem"
      : index === 9
      ? "3rem"
      : index === 10
      ? "-2rem"
      : "0";
  const margintop768 =
    index === 0
      ? "5.5rem"
      : index === 1
      ? "3rem"
      : index === 2
      ? "8rem"
      : index === 3
      ? "2rem"
      : index === 4
      ? "9rem"
      : index === 5
      ? "1.5rem"
      : index === 6
      ? "4rem"
      : index === 7
      ? "7rem"
      : index === 8
      ? "9.5rem"
      : index === 9
      ? "3rem"
      : index === 10
      ? "7rem"
      : "0";
  const marginleft768 =
    index === 0
      ? "4.5rem"
      : index === 1
      ? "1rem"
      : index === 2
      ? "-2rem"
      : index === 3
      ? ".7rem"
      : index === 4
      ? "-2rem"
      : index === 5
      ? "1.2rem"
      : index === 6
      ? "-2rem"
      : index === 7
      ? "-2rem"
      : index === 8
      ? "-1rem"
      : index === 9
      ? "3rem"
      : index === 10
      ? "-2rem"
      : "0";
  const margintop511 =
    index === 0
      ? "5.2rem"
      : index === 1
      ? "3rem"
      : index === 2
      ? "7.5rem"
      : index === 3
      ? "2rem"
      : index === 4
      ? "9rem"
      : index === 5
      ? "1.5rem"
      : index === 6
      ? "4rem"
      : index === 7
      ? "6.3rem"
      : index === 8
      ? "9rem"
      : index === 9
      ? "3rem"
      : index === 10
      ? "7rem"
      : "0";
  const marginleft511 =
    index === 0
      ? "4.5rem"
      : index === 1
      ? "1rem"
      : index === 2
      ? "-1.4rem"
      : index === 3
      ? "0.4rem"
      : index === 4
      ? "-1.5rem"
      : index === 5
      ? "1.2rem"
      : index === 6
      ? "-2rem"
      : index === 7
      ? "-1rem"
      : index === 8
      ? "-1rem"
      : index === 9
      ? "3rem"
      : index === 10
      ? "-1.3rem"
      : "0";
  const margintop469 =
    index === 0
      ? "4.8rem"
      : index === 1
      ? "3rem"
      : index === 2
      ? "6.5rem"
      : index === 3
      ? "2rem"
      : index === 4
      ? "7.7rem"
      : index === 5
      ? "1.5rem"
      : index === 6
      ? "3.7rem"
      : index === 7
      ? "6.1rem"
      : index === 8
      ? "8rem"
      : index === 9
      ? "3rem"
      : index === 10
      ? "6rem"
      : "0";
  const marginleft469 =
    index === 0
      ? "4rem"
      : index === 1
      ? "1rem"
      : index === 2
      ? "-1.4rem"
      : index === 3
      ? "0.4rem"
      : index === 4
      ? "-1.5rem"
      : index === 5
      ? "1.2rem"
      : index === 6
      ? "-1.5rem"
      : index === 7
      ? "-1.5rem"
      : index === 8
      ? "-1rem"
      : index === 9
      ? "2rem"
      : index === 10
      ? "-1.3rem"
      : "0";
  const margintop425 =
    index === 0
      ? "4.2rem"
      : index === 1
      ? "2rem"
      : index === 2
      ? "6rem"
      : index === 3
      ? "1rem"
      : index === 4
      ? "7rem"
      : index === 5
      ? "1rem"
      : index === 6
      ? "3rem"
      : index === 7
      ? "5rem"
      : index === 8
      ? "7rem"
      : index === 9
      ? "3rem"
      : index === 10
      ? "5.7rem"
      : "0";
  const marginleft425 =
    index === 0
      ? "3rem"
      : index === 1
      ? "1rem"
      : index === 2
      ? "-1.4rem"
      : index === 3
      ? "0.4rem"
      : index === 4
      ? "-1.5rem"
      : index === 5
      ? "1.2rem"
      : index === 6
      ? "-1.5rem"
      : index === 7
      ? "-1.5rem"
      : index === 8
      ? "-1rem"
      : index === 9
      ? "2rem"
      : index === 10
      ? "-1.3rem"
      : "0";
  const NoneDisplay = index >= 11 ? "none" : "block";
  return {
    marginTopIndexs,
    marginLeftIndexs,
    margintop1410,
    marginleft1410,
    margintop1260,
    marginleft1260,
    margintop1170,
    marginleft1170,
    margintop1067,
    marginleft1067,
    margintop992,
    marginleft992,
    margintop768,
    marginleft768,
    margintop511,
    marginleft511,
    margintop469,
    marginleft469,
    margintop425,
    marginleft425,
    NoneDisplay,
  };
};

export const StyledCircle = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px dashed white;
  background-color: transparent;
  margin-top: ${(props) => props.marginTop || "0"};
  margin-left: ${(props) => props.marginLeft || "0"};
  display: ${(props) => props.NoneDisplay}; // Use NoneDisplay from props

  z-index: 9999;
  &:hover {
    border-color: #00f;
  }

  @media (max-width: 1410px) {
    width: 35px;
    height: 35px;
    margin-top: ${(props) => props.marginTop1410};
    margin-left: ${(props) => props.marginLeft1410};
  }
  @media (max-width: 1260px) {
    width: 35px;
    height: 35px;
    margin-top: ${(props) => props.marginTop1260};
    margin-left: ${(props) => props.marginLeft1260};
  }
  @media (max-width: 1170px) {
    width: 35px;
    height: 35px;
    margin-top: ${(props) => props.marginTop1170};
    margin-left: ${(props) => props.marginLeft1170};
  }
  @media (max-width: 1067px) {
    width: 35px;
    height: 35px;
    margin-top: ${(props) => props.marginTop1067};
    margin-left: ${(props) => props.marginLeft1067};
  }
  @media (max-width: 992px) {
    width: 35px;
    height: 35px;
    margin-top: ${(props) => props.marginTop992};
    margin-left: ${(props) => props.marginLeft992};
  }
  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
    margin-top: ${(props) => props.marginTop768};
    margin-left: ${(props) => props.marginLeft768};
  }
  @media (max-width: 511px) {
    width: 23px;
    height: 23px;
    margin-top: ${(props) => props.marginTop511};
    margin-left: ${(props) => props.marginLeft511};
  }
  @media (max-width: 469px) {
    width: 23px;
    height: 23px;
    margin-top: ${(props) => props.marginTop469};
    margin-left: ${(props) => props.marginLeft469};
  }
  @media (max-width: 425px) {
    width: 23px;
    height: 23px;
    margin-top: ${(props) => props.marginTop425};
    margin-left: ${(props) => props.marginLeft425};
  }
`;

