import React, { useEffect, useRef, useState } from "react";
import arrow1 from "../assets/arrow1.png";
import profile1 from "../assets/profile1.png";
import { IoIosNotifications } from "react-icons/io";
import { IoSearchOutline, IoSettings } from "react-icons/io5";
import Sidebar from "./Sidebar";
import MobileTopNav from "./MobileTopNav";
import CoachesTable from "./CoachesTable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import ExcelJS from "exceljs";
import { useParams } from "react-router-dom";
import BudgetsCoachesTable from "./BudgetsCoachesTable";
import BudgetsTeamsTable from "./BudgetsTeamsTable";
import ImageComponent from "./ImageComponent";

const Budget = () => {
  const { id } = useParams();
  const [budgets, setBudgets] = useState({
    players: [],
    positions: [],
    month_salary: [],
    month_payment: [],
    year_income: [],
    bonus: [],
    salary_bonus: [],
    social_fee: [],
    total_cost: [],
  });

  const [positions, setPositions] = useState([]);
  const [member, setMember] = useState([]);

  useEffect(() => {
    // console.log("Team ID from URL:", id);
    const fetchData = async () => {
      try {
        // Fetch data for players
        const playersResponse = await axios.get(
          `https://admin.smguld.com/api/v1/members?team_id=${id}`
        );
        // Log the team_id from the first player's data
        const teamIds = playersResponse.data.map((player) => player.team_id);
        // console.log("Team IDs:", teamIds);
        setMember(playersResponse.data);

        // Check if the current team_id exists in the fetched data
        if (!teamIds.includes(parseInt(id))) {
          console.log("Team ID does not match any player in the data.");
          return;
        }

        // Filter playersData based on matching team_id
        const playersData = playersResponse.data.filter(
          (player) => player.team_id === parseInt(id)
        );

        // Fetch data for positions
        const positionsResponse = await axios.get(
          "https://admin.smguld.com/api/v1/positions"
        );
        const positionsData = positionsResponse.data;
        setPositions(positionsData);

        // Fetch data for budgets (replace with your actual API endpoint)
        const budgetsResponse = await axios.get(
          `https://admin.smguld.com/api/v1/budget?team_id=${id}`
        );
        const budgetsData = budgetsResponse.data;

        // Combine data and update state
        setBudgets({
          players: playersData,
          positions: positionsData,
          month_salary: budgetsData.map((entry) => entry.month_salary),
          month_payment: budgetsData.map((entry) => entry.month_payment),
          year_income: budgetsData.map((entry) => entry.year_income),
          bonus: budgetsData.map((entry) => entry.bonus),
          salary_bonus: budgetsData.map((entry) => entry.salary_bonus),
          social_fee: budgetsData.map((entry) => entry.social_fee),
          total_cost: budgetsData.map((entry) => entry.total_cost),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]); // Add 'id' to the dependency array to re-fetch data when 'id' changes

  const handleInputChange = (value, column, row) => {
    setBudgets((prevBudgets) => {
      const newBudgets = { ...prevBudgets };
      newBudgets[column][row] = parseFloat(value) || 0;
      return newBudgets;
    });
  };

  const calculateTotal = (columnName) => {
    return member.reduce((sum, coach) => sum + coach[columnName], 0);
  };

  const getPositionName = (positionId) => {
    const position = positions.find((pos) => pos.id === positionId);

    return position ? position.label : "s";
  };

  // Assume you want to obtain the team_id from the first player's data
  const firstPlayer = budgets.players[0];
  const team_id = firstPlayer ? firstPlayer.team_id : null;
  // console.log(id);

  // pdf generate
  const tableRef = useRef();
  const buttonsRef = useRef();

  const handleGeneratePDF = async () => {
    try {
      // Convert the table to an image using html2canvas
      const canvas = await html2canvas(tableRef.current);
      const buttonsCanvas = await html2canvas(buttonsRef.current);

      // Create a PDF using jsPDF
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(canvas.toDataURL("image/png"), "PNG", 10, 10, 190, 0);

      // Save the PDF
      pdf.save("Teams-budget.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  //excel generate
  const handleGenerateExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Coaches");

      // Add headers
      worksheet.addRow([
        "Player",
        "Position",
        "Monthly S.",
        "Monthly P.",
        "Year Income",
        "Bonus",
        "Total S & B",
        "SF",
        "Total Cost",
      ]);

      // Add data rows
      budgets.players.forEach((player, rowIndex) => {
        const rowData = [
          player.full_name,
          getPositionName(player.position_id),
          ...Object.keys(budgets)
            .slice(2)
            .map((column) => budgets[column][rowIndex]),
        ];
        worksheet.addRow(rowData);
      });

      // Add total row
      worksheet.addRow([
        "Total",
        "",
        ...Object.keys(budgets)
          .slice(2)
          .map((column) => calculateTotal(column)),
      ]);

      // Generate a blob from the Excel workbook
      const blob = await workbook.xlsx.writeBuffer();

      // Create a download link and trigger a click event
      const link = document.createElement("a");
      link.href = URL.createObjectURL(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      link.download = "generated-excel.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  const calculateRowTotal = (player) => {
    return (
      player.salary +
      player.monthly_payment +
      player.bonus +
      player.social_security
    );
  };

  const CalculateYearlyIncome = (player) => {
    return player.salary * player.month_payment;
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-3 bg-left-color px-4 py-3 height-side-bar main-sidebar-mobile">
          <div className="bg-left-color-text">
            <Sidebar />
          </div>
        </div>
        <div className="col-md-8 height-full">
          <div className="pt-4 row mobile-view-top-nav">
            <div className="col-md-1 mt-2 text-center">
              <ImageComponent />
            </div>
            <div className="col-md-7">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-4 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-2 bg-left-color-card">
                <div className="container">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div
                      className="main-card col-md-11 bg-left-color-card"
                      ref={tableRef}
                    >
                      <div className="container py-3 row">
                        <h5 className="col-md-6 mt-1">My Teams Budget</h5>
                      </div>
                      {/* Main Screen Table */}
                      {/* Players Table */}
                      <div class="table-responsive mt-4">
                        <h5>Player's Budgets</h5>
                        <table class="table table-sm">
                          <thead>
                            <tr>
                              <th
                                class="custom-th"
                                style={{ width: "30%!important" }}
                              >
                                Player
                              </th>
                              <th>Position</th>
                              <th>Monthly S.</th>
                              <th>Monthly P.</th>
                              <th>Year Income</th>
                              <th>Bonus</th>
                              <th>Total S &amp; B</th>
                              <th>SF</th>
                              <th>Total Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            {budgets.players.map((player, rowIndex) => (
                              <tr key={rowIndex} className="row_data">
                                <td>{player.full_name}</td>
                                <td>{getPositionName(player.position_id)}</td>

                                <td>
                                  <input
                                    type="text"
                                    value={player.salary}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={player.monthly_payment}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={CalculateYearlyIncome(player)}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={player.bonus}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={player.bonus + player.salary}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={CalculateYearlyIncome(player) * 0.32}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={calculateRowTotal(player)}
                                    className="custom_input_size"
                                  />
                                </td>
                              </tr>
                            ))}

                            <tr style={{ borderTop: "2px solid #fff" }}>
                              <td colSpan="2">Total</td>
                              <td>{calculateTotal("salary")}</td>
                              <td>{calculateTotal("monthly_payment")}</td>
                              <td>{calculateTotal("yearly_income")}</td>
                              <td>{calculateTotal("bonus")}</td>
                              <td>
                                {calculateTotal("salary") +
                                  calculateTotal("bonus")}
                              </td>
                              <td>{calculateTotal("salary") * 0.32}</td>
                              <td>
                                {calculateTotal("salary") +
                                  calculateTotal("bonus") +
                                  calculateTotal("salary") * 0.32}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <BudgetsCoachesTable team_id={team_id} />
                      <BudgetsTeamsTable team_id={team_id} />

                      <div class="row mt-5" ref={buttonsRef}>
                        <div class="mb-3 col-md-12">
                          <a class=" btn btn-dark" onClick={handleGeneratePDF}>
                            Generate PDF
                          </a>
                          <button
                            class="btn btn-info mx-2"
                            onClick={handleGenerateExcel}
                          >
                            Excel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View */}
        <div className="col-md-12 height-full-add-player">
          <div className="pt-4 row mobile-view-top-nav">
            <ImageComponent />
            <div className="col-md-7">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-4 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-2 bg-left-color-card">
                <div className="container">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="main-card col-md-11 bg-left-color-card">
                      <div className="container py-3 row">
                        <h5 className="col-md-6 mt-1">My Teams Budget</h5>
                      </div>
                      <div class="table-responsive">
                        <h4>Player's Budgets</h4>
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th
                                class="custom-th"
                                style={{ width: "30%!important" }}
                              >
                                Player
                              </th>
                              <th>Position</th>
                              <th>Monthly S.</th>
                              <th>Monthly P.</th>
                              <th>Year Income</th>
                              <th>Bonus</th>
                              <th>Total S &amp; B</th>
                              <th>SF</th>
                              <th>Total Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            {budgets.players.map((player, rowIndex) => (
                              <tr key={rowIndex} className="row_data">
                                <td>{player.full_name}</td>
                                <td>{getPositionName(player.position_id)}</td>

                                <td>
                                  <input
                                    type="text"
                                    value={player.salary}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={player.monthly_payment}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={CalculateYearlyIncome(player)}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={player.bonus}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={player.bonus + player.salary}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={CalculateYearlyIncome(player) * 0.32}
                                    className="custom_input_size"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={calculateRowTotal(player)}
                                    className="custom_input_size"
                                  />
                                </td>
                              </tr>
                            ))}

                            <tr style={{ borderTop: "2px solid #fff" }}>
                              <td colSpan="2">Total</td>
                              <td>{calculateTotal("salary")}</td>
                              <td>{calculateTotal("monthly_payment")}</td>
                              <td>{calculateTotal("yearly_income")}</td>
                              <td>{calculateTotal("bonus")}</td>
                              <td>
                                {calculateTotal("salary") +
                                  calculateTotal("bonus")}
                              </td>
                              <td>{calculateTotal("salary") * 0.32}</td>
                              <td>
                                {calculateTotal("salary") +
                                  calculateTotal("bonus") +
                                  calculateTotal("salary") * 0.32}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <BudgetsCoachesTable team_id={team_id} />
                      <BudgetsTeamsTable team_id={team_id} />

                      <div class="row mt-5" ref={buttonsRef}>
                        <div class="mb-3 col-md-12">
                          <a class=" btn btn-dark" onClick={handleGeneratePDF}>
                            Generate PDF
                          </a>
                          <button
                            class="btn btn-info mx-2"
                            onClick={handleGenerateExcel}
                          >
                            Excel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Budget;
