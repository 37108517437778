import React, { useEffect, useState } from "react";
import arrow1 from "../assets/arrow1.png";
import profile1 from "../assets/profile1.png";
import ground from "../assets/ground.png";
import { IoIosNotifications } from "react-icons/io";
import { IoSearchOutline, IoSettings } from "react-icons/io5";
import Sidebar from "./Sidebar";
import MobileSidebar from "./MobileSidebar";
import MobileTopNav from "./MobileTopNav";
import { useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { StyledCircle, getMarginValues } from "./styles";
import axios from "axios";
import DummyImage from "../assets/coachDummy.png";
import ImageComponent from "./ImageComponent";

const DraggablePlayer = ({ playerName, imgSrc }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "PLAYER",
    item: { playerName, imgSrc },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1, cursor: "move" }}>
      <div className="img mx-1">
        <img
          src={imgSrc}
          alt={playerName}
          style={{ width: "35px", borderRadius: " 44px", height: "36px" }}
        />
        <p className="board-profile">{playerName}</p>
      </div>
    </div>
  );
};

const DroppedPlayer = ({
  setDroppedItems,
  droppedItems,
  playerName,
  index,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: "PLAYER",
    drop: (item) => setDroppedItems([...droppedItems, { ...item, playerName }]),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const {
    marginTopIndexs,
    marginLeftIndexs,
    margintop1410,
    marginleft1410,
    margintop1260,
    marginleft1260,
    margintop1170,
    marginleft1170,
    margintop1067,
    marginleft1067,
    margintop992,
    marginleft992,
    margintop768,
    marginleft768,
    margintop511,
    marginleft511,
    margintop469,
    marginleft469,
    margintop425,
    marginleft425,
    NoneDisplay
  } = getMarginValues(index);

  return (
    <>
      <StyledCircle
        ref={drop}
        marginTop={marginTopIndexs}
        marginLeft={marginLeftIndexs}
        marginTop1410={margintop1410}
        marginLeft1410={marginleft1410}
        marginTop1260={margintop1260}
        marginLeft1260={marginleft1260}
        marginTop1170={margintop1170}
        marginLeft1170={marginleft1170}
        marginTop1067={margintop1067}
        marginLeft1067={marginleft1067}
        marginTop992={margintop992}
        marginLeft992={marginleft992}
        marginTop768={margintop768}
        marginLeft768={marginleft768}
        marginTop511={margintop511}
        marginLeft511={marginleft511}
        marginTop469={margintop469}
        marginLeft469={marginleft469}
        marginTop425={margintop425}
        marginLeft425={marginleft425}
        NoneDisplay={NoneDisplay}
      >
        {isOver && <p>Drop here</p>}
        {droppedItems
          .filter((item) => item.playerName === playerName)
          .map((item, index) => (
            <img
              key={index}
              src={item.imgSrc}
              style={{
                width: "35px",
                borderRadius: "44px",
                height: "36px",
                marginLeft: "6px",
                marginTop: "4px",
              }}
              alt={item.playerName}
            />
          ))}
      </StyledCircle>
    </>
  );
};

const TeamsBoard = () => {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://admin.smguld.com/api/v1/members"
        );
        const membersData = response.data;

        // Extract relevant data and update state
        const updatedPlayers = membersData.map((member) => ({
          playerName: member.full_name, // Assuming the member object has a 'name' property
          imgSrc: member.image, // Replace with the actual property name for the image source
        }));

        setPlayers(updatedPlayers);
      } catch (error) {
        console.error("Error fetching members data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to ensure the effect runs only once during component mount

  // Render your component using the 'players' state

  const [droppedItems, setDroppedItems] = useState([]);

  return (
    <div>
      <div className="row">
        <div className="col-md-3 bg-left-color px-4 py-3 height-side-bar main-sidebar-mobile">
          <div className="bg-left-color-text">
            <Sidebar />
          </div>
        </div>
        <div className="col-md-8 height-full-homepage">
          <div className="pt-4 row ">
            <div className="col-md-1 mt-2 text-center">
              <ImageComponent />
            </div>
            <div className="col-md-7">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-4 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} className="profile-img-nav" alt="" />
                  <p className="mt-1 profile-text-nav">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <div className="container ">
            <div className="row mt-3">
              <div className="main-card col-md-12 mx-5 bg-left-color-card">
                <div className="container pt-3">
                  <h5 className="text-center">My Teams Board</h5>
                </div>

                <div
                  className="bgc-cards d-flex justify-content-center pt-3"
                  style={{ flexWrap: "wrap" }}
                >
                  {players.map((player, index) => (
                    <DraggablePlayer
                      key={index}
                      playerName={player.playerName}
                      style={{ width: "20px", marginLeft: "50px" }}
                      imgSrc={
                        player.imgSrc
                          ? `data:image/png;base64,${player.imgSrc}`
                          : DummyImage
                      }
                    />
                  ))}
                </div>

                <div className="mt-3 d-flex justify-content-center position-absolute">
                  {players.map((player, index) => (
                    <DroppedPlayer
                      key={index}
                      setDroppedItems={setDroppedItems}
                      droppedItems={droppedItems}
                      playerName={player.playerName}
                      index={index}
                    />
                  ))}
                </div>

                <div className="mt-3 d-flex justify-content-center">
                  <img className="ground-img" src={ground} alt="Ground" />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex button-export col-12 mt-3">
            <button className="btn btn-primary">Save</button>
          </div>
        </div>
        <div className="col-md-11 height-1069">
          <div className="pt-4 row mobile-view-top-nav">
            <div className="col-md-2 mt-2 text-center row">
              <div className="col-md-6">
                <MobileSidebar />
              </div>
              <div className="col-md-6">
                <img src={arrow1} alt="" />
              </div>
            </div>
            <div className="col-md-7 mx-3">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-3 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} className="profile-img-nav" alt="" />
                  <p className="mt-1 profile-text-nav">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container ">
            <div className="row mt-3">
              <div className="main-card col-md-12 bg-left-color-card">
                <div className="container pt-3">
                  <h5 className="text-center">My Teams Board</h5>
                </div>

                <div
                  className="bgc-cards d-flex justify-content-center pt-3"
                  style={{ flexWrap: "wrap" }}
                >
                  {players.map((player, index) => (
                    <DraggablePlayer
                      key={index}
                      playerName={player.playerName}
                      style={{ width: "20px", marginLeft: "50px" }}
                      imgSrc={
                        player.imgSrc
                          ? `data:image/png;base64,${player.imgSrc}`
                          : DummyImage
                      }
                    />
                  ))}
                </div>

                <div className="mt-3 d-flex justify-content-center position-absolute">
                  {players.map((player, index) => (
                    <DroppedPlayer
                      key={index}
                      setDroppedItems={setDroppedItems}
                      droppedItems={droppedItems}
                      playerName={player.playerName}
                      index={index}
                    />
                  ))}
                </div>

                <div className="mt-3 d-flex justify-content-center">
                  <img className="ground-img" src={ground} alt="Ground" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default TeamsBoard;
