import React, { useState } from "react";
import arrow1 from "../assets/arrow1.png";
import profile1 from "../assets/profile1.png";
import { IoIosNotifications } from "react-icons/io";
import {
  IoSearchOutline,
  IoSettings,
  IoSearch,
  IoCheckbox,
} from "react-icons/io5";
import Sidebar from "./Sidebar";
import logo from "../assets/white_on_blue.png";
import MobileTopNav from "./MobileTopNav";
import ImageComponent from "./ImageComponent";

const GeneralSettings = () => {
  return (
    <div>
      <div className="row">
        <div className="col-md-3 bg-left-color px-4 py-3 height-side-bar main-sidebar-mobile">
          <div className="bg-left-color-text">
            <Sidebar />
          </div>
        </div>
        <div className="col-md-8 height-full">
          <div className="pt-4 row">
          <div className="col-md-1 mt-2 text-center">
            
            <ImageComponent/>
            </div>
            <div className="col-md-8">
              <h4 className="mb-0">Good Evening, Pank!</h4>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-3 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 pb-3">
                <h4>Company Settings</h4>
                <div className="container py-3 row bg-left-color-card mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Site Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Starter Kit"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name@example.com"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Phone Number
                        </label>
                        <br />
                        <select
                          name="position"
                          id="position"
                          className="position"
                        >
                          <option value="position">03291938283</option>
                          <option value="Captian">02938193828</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 pb-3">
                <h4>Media & File Uploads</h4>
                <div className="container py-3 row bg-left-color-card mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          <img style={{ width: "45px" }} src={logo} alt="" />
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Starter Kit"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          s
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="skiwo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 pb-3">
                <h4>Finance</h4>
                <div className="container py-3 row bg-left-color-card mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Currency Symbols
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="$"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Tax Percentage
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="1"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          IS Tax
                        </label>
                        <br />
                        <select
                          name="position"
                          id="position"
                          className="position"
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      Auto Approve
                      <label class="switch mx-2 mt-2">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      Auto Reminder
                      <label class="switch mx-2 mt-2">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 pb-3">
                <h4>Address</h4>
                <div className="container py-3 row bg-left-color-card mt-3">
                  <div className="row">
                  <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Country
                        </label>
                        <br />
                        <select
                          name="position"
                          id="position"
                          className="position"
                        >
                          <option value="position">USA</option>
                          <option value="Captian">Pakistan</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          State
                        </label>
                        <br />
                        <select
                          name="position"
                          id="position"
                          className="position"
                        >
                          <option value="position">California</option>
                          <option value="Captian">Ciatle</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          City
                        </label>
                        <br />
                        <select
                          name="position"
                          id="position"
                          className="position"
                        >
                          <option value="position">New York</option>
                          <option value="Captian">Washington DC.</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          District
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="District"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Zip Code
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="12345"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Store Tax Number
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="tax@tax"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Address"
                        />
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div className="container d-flex justify-content-end">
              <button className="btn btn-primary">
                Update
              </button>
            </div>
          
        </div>


        <div className="col-md-12 height-full-add-player">
          <div className="pt-4 row mobile-view-top-nav">
            <ImageComponent/>
            <div className="col-md-8">
              <h4 className="mb-0">Good Evening, Pank!</h4>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-3 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />


          <div className="container">
            <div className="row" style={{justifyContent:"center", marginLeft:"15px"}}>
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 pb-3">
                <h4>Company Settings</h4>
                <div className="container py-3 row bg-left-color-card mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Site Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Starter Kit"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name@example.com"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Phone Number
                        </label>
                        <br />
                        <select
                          name="position"
                          id="position"
                          className="position"
                        >
                          <option value="position">03291938283</option>
                          <option value="Captian">02938193828</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row" style={{justifyContent:"center", marginLeft:"15px"}}>
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 pb-3">
                <h4>Media & File Uploads</h4>
                <div className="container py-3 row bg-left-color-card mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          <img style={{ width: "45px" }} src={logo} alt="" />
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Starter Kit"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          s
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="skiwo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row" style={{justifyContent:"center", marginLeft:"15px"}}>
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 pb-3">
                <h4>Finance</h4>
                <div className="container py-3 row bg-left-color-card mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Currency Symbols
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="$"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Tax Percentage
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="1"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          IS Tax
                        </label>
                        <br />
                        <select
                          name="position"
                          id="position"
                          className="position"
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      Auto Approve
                      <label class="switch mx-2 mt-2">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      Auto Reminder
                      <label class="switch mx-2 mt-2">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="container">
            <div className="row" style={{justifyContent:"center", marginLeft:"15px"}}>
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 pb-3">
                <h4>Address</h4>
                <div className="container py-3 row bg-left-color-card mt-3">
                  <div className="row">
                  <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Country
                        </label>
                        <br />
                        <select
                          name="position"
                          id="position"
                          className="position"
                        >
                          <option value="position">USA</option>
                          <option value="Captian">Pakistan</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          State
                        </label>
                        <br />
                        <select
                          name="position"
                          id="position"
                          className="position"
                        >
                          <option value="position">California</option>
                          <option value="Captian">Ciatle</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          City
                        </label>
                        <br />
                        <select
                          name="position"
                          id="position"
                          className="position"
                        >
                          <option value="position">New York</option>
                          <option value="Captian">Washington DC.</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          District
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="District"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Zip Code
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="12345"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Store Tax Number
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="tax@tax"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Address"
                        />
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div className="container d-flex justify-content-end">
              <button className="btn btn-primary">
                Update
              </button>
            </div>
          
        </div>




      </div>
    </div>
  );
};

export default GeneralSettings;
