import React from 'react'
import { useNavigate } from 'react-router-dom';
import arrow1 from "../assets/arrow1.png"

const ImageComponent = () => {
    const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <div>
      <img src={arrow1} alt="" onClick={handleBackClick} style={{ cursor: 'pointer' }} />
    
    </div>
  )
}

export default ImageComponent