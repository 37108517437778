import React, { useEffect, useState } from "react";
import arrow1 from "../assets/arrow1.png";
import profile1 from "../assets/profile1.png";
import { IoIosNotifications } from "react-icons/io";
import { IoSearchOutline, IoSettings, IoSearch } from "react-icons/io5";
import Sidebar from "./Sidebar";
import MobileTopNav from "./MobileTopNav";
import axios from "axios";
import PlayerRow from "./PlayerRow";
import { Link } from "react-router-dom";
import ImageComponent from "./ImageComponent";

const MyPlayer = () => {
  const [players, setPlayers] = useState([]);
  const [positions, setPositions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedCount, setSelectedCount] = useState(8); // Initialize with the total count
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data for players
        const playersResponse = await axios.get(
          "https://admin.smguld.com/api/v1/members"
        );
        const playersData = playersResponse.data;
        setPlayers(playersData);

        // Fetch data for positions
        const positionsResponse = await axios.get(
          "https://admin.smguld.com/api/v1/positions"
        );
        const positionsData = positionsResponse.data;
        setPositions(positionsData);

        // Fetch data for teams
        const teamsResponse = await axios.get(
          "https://admin.smguld.com/api/v1/teams"
        );
        const teamsData = teamsResponse.data;
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const getPositionName = (positionId) => {
    const position = positions.find((pos) => pos.id === positionId);
    return position ? position.label : "Unknown Position";
  };

  const getTeamName = (teamId) => {
    const team = teams.find((t) => t.id === teamId);
    return team ? team.team_name : "Unknown Team";
  };

  const handleSelectChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setSelectedCount(count);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredPlayers = players.filter(
    (player) =>
      player.full_name &&
      player.full_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Show a message when no matching results are found
  const noResultsMessage =
    searchQuery && filteredPlayers.length === 0 ? (
      <div className="text-center mt-3">
        <p>No results found for "{searchQuery}".</p>
      </div>
    ) : null;

  const [showModal, setShowModal] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3 bg-left-color px-4 py-3 height-side-bar main-sidebar-mobile">
          <div className="bg-left-color-text">
            <Sidebar />
          </div>
        </div>
        <div className="col-md-8 height-full">
          <div className="pt-4 row">
            <div className="col-md-1 mt-2 text-center">
            
            <ImageComponent/>
            </div>
            <div className="col-md-8">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-3 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12 mx-5 bg-left-color-card">
                <div className="container py-3 row">
                  <h5 className="col-md-6 mt-1">My Player List</h5>
                  <h5 className="col-md-4">
                    <div className="input-group">
                      <input
                        className="form-control border"
                        type="search"
                        placeholder="Search by name"
                        id="example-search-input"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <span className="input-group-append">
                        <button
                          className="btn btn-outline-secondary border-start-0 border-bottom-0 border ms-n5"
                          type="button"
                        >
                          <IoSearch />
                        </button>
                      </span>
                    </div>
                  </h5>
                  <button className="btn btn-secondary col-md-2 pt-2 h-50">
                    <Link
                      to="/add-player"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <span>Add Player</span>
                    </Link>
                  </button>
                </div>

                <div
                  className="container row pb-3"
                  style={{ overflowX: "auto" }}
                >
                  {filteredPlayers.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col table-row-1">#</th>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Age</th>
                          <th scope="col">Nationality</th>
                          <th scope="col">Position</th>
                          <th scope="col">Team</th>
                          <th scope="col">Profile</th>
                          <th scope="col">Salary</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {players
                          .slice(0, selectedCount)
                          .map((player, index) => (
                            <PlayerRow
                              key={player.id}
                              player={player}
                              index={index}
                              getPositionName={getPositionName}
                              getTeamName={getTeamName}
                              searchQuery={searchQuery}
                            />
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    noResultsMessage
                  )}
                </div>

                <div className="container d-flex pb-3 justify-content-start">
                  Showing {players.length} of {selectedCount} results
                  <select
                    id="selection"
                    className="mx-2"
                    value={selectedCount} // Set the value attribute to selectedCount
                    onChange={handleSelectChange}
                  >
                    {Array.from({ length: players.length }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

           
          </div>
        </div>

        <div className="col-md-12 height-full-add-player">
          <div className="pt-4 row mobile-view-top-nav">
            <div className="col-md-1 mt-2 text-center">
            
            <ImageComponent/>
            </div>
            <div className="col-md-7">
              <h5 className="mb-0">Good Evening, Pank!</h5>
              <p>Today July 23, 2023 | 7:23 PM</p>
            </div>
            <div className="col-md-3 row">
              <div className="px-2 mt-1 col-md-2">
                <IoSearchOutline className="w-100 icon-40" />
              </div>
              <div className="px-2 mt-1 col-md-3">
                <IoIosNotifications className="icon-40" />
              </div>
              <div className="px-2 col-md-6 border-settings ">
                <div className="profile d-flex ">
                  <img src={profile1} alt="" />
                  <p className="mt-1">Pank</p>
                  <p className="mt-1 mx-1">|</p>
                  <IoSettings className="mt-2 mx-1 icon-40" />
                </div>
              </div>
            </div>
          </div>

          <MobileTopNav />

          <div className="container">
            <div
              className="row"
              style={{ justifyContent: "center", marginLeft: "4px" }}
            >
              {/* <div className="col-md-1"></div> */}
              <div className="main-card col-md-12  bg-left-color-card">
                <div className="container py-3 row">
                  <h5 className="col-md-6 mt-1">My Player List</h5>
                  <h5 className="col-md-4">
                    <div className="input-group">
                      <input
                        className="form-control border"
                        type="search"
                        placeholder="Search by name"
                        id="example-search-input"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <span className="input-group-append">
                        <button
                          className="btn btn-outline-secondary border-start-0 border-bottom-0 border ms-n5"
                          type="button"
                        >
                          <IoSearch />
                        </button>
                      </span>
                    </div>
                  </h5>
                  <button className="btn btn-secondary col-md-2 pt-2 h-50">
                    <Link
                      to="/add-player"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <span>Add Player</span>
                    </Link>
                  </button>
                </div>

                <div
                  className="container row pb-3"
                  style={{ overflowX: "auto" }}
                >
                  {filteredPlayers.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col table-row-1">#</th>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Age</th>
                          <th scope="col">Nationality</th>
                          <th scope="col">Position</th>
                          <th scope="col">Team</th>
                          <th scope="col">Profile</th>
                          <th scope="col">Salary</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {players
                          .slice(0, selectedCount)
                          .map((player, index) => (
                            <PlayerRow
                              key={player.id}
                              player={player}
                              index={index}
                              getPositionName={getPositionName}
                              getTeamName={getTeamName}
                              searchQuery={searchQuery}
                            />
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    noResultsMessage
                  )}
                </div>

                <div className="container d-flex pb-3 justify-content-start">
                  Showing {selectedCount} of {players.length} results
                  <select
                    id="selection"
                    className="mx-2"
                    value={selectedCount} // Set the value attribute to selectedCount
                    onChange={handleSelectChange}
                  >
                    {Array.from({ length: players.length }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPlayer;
