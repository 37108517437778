import axios from "axios";
import { useEffect, useState } from "react";

const BudgetsCoachesTable = ({ team_id }) => {
  const [coaches, setCoaches] = useState([]);
  const [total, setTotal] = useState([]);

  useEffect(() => {
    const fetchCoaches = async () => {
      try {
        const response = await axios.get(
          `https://admin.smguld.com/api/v1/coaches?team_id=${team_id}`
        );

        setCoaches(response.data);
      } catch (error) {
        console.error("Error fetching coaches:", error);
      }
    };

    fetchCoaches();
  }, [team_id]);


  const calculateTotal = (columnName) => {
    return coaches.reduce((sum, coach) => sum + coach[columnName], 0);
  };


  return (
    <div>
      <h5 className="mt-3">Coaches</h5>
      <div className="table-responsive mt-4">
        <table className="table table-sm">
          <thead>
            <tr>
              <th class="custom-th" style={{ width: "30%!important" }}>
                Player
              </th>
              <th>Position</th>
              <th>Monthly S.</th>
              <th>Monthly P.</th>
              <th>Year Income</th>
              <th>Bonus</th>
              <th>Total S &amp; B</th>
              <th>SF</th>
              <th>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {coaches.map((coach) => (
              <tr key={coach.id}>
                <td>{coach.name}</td>
                <td>{coach.position}</td>
                <td>
                  <input
                    type="text"
                    value={coach.salary}
                    className="custom_input_size"
                  />
                </td>
                {/* Add input fields for other properties */}
                <td>
                  <input
                    type="text"
                    className="custom_input_size"
                    value={coach.monthly_payment}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="custom_input_size"
                    value={coach.yearly_income}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="custom_input_size"
                    value={coach.bonus}
                    name="bonus"

                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="custom_input_size"
                    value={coach.salary + coach.bonus}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="custom_input_size"
                    value={coach.salary * 0.32}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="custom_input_size"
                    value={coach.salary + coach.bonus + coach.salary * 0.32}
                    readOnly
                  />
                </td>
                {/* Add other input fields based on your data */}
                <td>{/* Add action buttons or links if needed */}</td>
              </tr>
            ))}

            <tr style={{ borderTop: "2px solid #fff" }}>
              <td colSpan="2">Total</td>
              <td>{calculateTotal("salary")}</td>
              <td>{calculateTotal("monthly_payment")}</td>
              <td>{calculateTotal("yearly_income")}</td>
              <td>{calculateTotal("bonus")}</td>
              <td>{calculateTotal("salary") + calculateTotal("bonus")}</td>
              <td>{calculateTotal("salary") * 0.32}</td>
              <td>
                {calculateTotal("salary") +
                  calculateTotal("bonus") +
                  calculateTotal("salary") * 0.32}
              </td>
              {/* Add other total columns based on your data */}
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BudgetsCoachesTable;
