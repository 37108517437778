import React, { useEffect, useState } from "react";
import { FaPencil, FaTrash } from "react-icons/fa6";
import axios from "axios";

const PlayerRow = ({
  player,
  index,
  getPositionName,
  getTeamName,
  searchQuery,
  handleInputChange,
  handleSaveButtonClick,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [positionsData, setPositionsData] = useState([]);
  const [membersData, setMembersData] = useState({
    id: "",
    user_id: "",
    full_name: "",
    position_id: "",
    team_id: "",
    type: "",
    img: "",
    age: "",
    nationality: "",
    salary: "",
    social_security: "",
    created_at: "",
    updated_at: "",
    monthly_payment: "",
    bonus: "",
  });

  // Function to handle file input change
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setMembersData((prevData) => ({
      ...prevData,
      image: file,
    }));
  };

  // Fetch members data when the component mounts or when player ID changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch player data based on ID
        const playerResponse = await axios.get(
          `https://admin.smguld.com/api/v1/members/${player.id}`
        );
        setMembersData(playerResponse.data);

        // Fetch positions data
        const positionsResponse = await axios.get(
          "https://admin.smguld.com/api/v1/positions"
        );
        setPositionsData(positionsResponse.data);
      } catch (error) {
        console.error(`Error fetching player data for ID ${player.id}:`, error);
      }
    };

    if (player.id) {
      fetchData();
    }
  }, [player.id]);

  // Function to toggle the modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  // Function to handle input change in the modal
  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setMembersData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle PATCH request to update player information
  const handleSaveButton = async () => {
    try {
      // Update player information using PATCH request
      await axios.patch(
        `https://admin.smguld.com/api/v1/members/${player.id}`,
        membersData
      );
      console.log("Player updated successfully");
    } catch (error) {
      console.error("Error updating player:", error);
    } finally {
      toggleModal();
    }
  };

  return (
    <tr key={player.id} className="row_data">
      <td>{index + 1}</td>
      <td>{player.id}</td>
      <td>{player.full_name}</td>
      <td>{player.age}</td>
      <td>{player.nationality}</td>
      <td>{getPositionName(player.position_id)}</td>
      <td>{getTeamName(player.team_id)}</td>
      <td>
        <img
          className="playersImg"
          src={`data:image/png;base64,${player.image}`}
          alt=""
          onError={(e) => {
            e.target.src =
              "https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png"; // replace with your dummy avatar image path
          }}
        />
      </td>
      <td>{player.salary}</td>
      <td className="d-flex">
        <button className="btn btn-success" onClick={toggleModal}>
          <FaPencil />
        </button>
        <button className="btn mx-2 w-10 h-10 btn-danger">
          <FaTrash />
        </button>
      </td>

      {/* Bootstrap modal for adding players */}
      <div
        className={`modal ${showModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Player Information</h5>
            </div>
            <div className="modal-body">
              {/* Add your form elements for adding a player here */}
              <form>
                {/* Your existing form elements */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="full_name"
                        className="form-label text-white"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control input-modal"
                        id="full_name"
                        name="full_name"
                        placeholder="Enter Full Name"
                        value={membersData.full_name}
                        onChange={handleModalInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="position_id"
                        className="form-label text-white"
                      >
                        Position
                      </label>
                      <select
                        name="position_id"
                        id="position_id"
                        className="form-control"
                        value={membersData.position_id}
                        onChange={handleModalInputChange}
                      >
                        <option value="">Select Position</option>
                        {positionsData.map((position) => (
                          <option key={position.id} value={position.id}>
                            {position.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label text-white"
                      >
                        Salary
                      </label>
                      <input
                        type="number"
                        className="form-control input-modal"
                        id="recipient-name"
                        placeholder="$ 2437"
                        value={membersData.salary}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="age" className="form-label text-white">
                        Age
                      </label>
                      <input
                        type="text"
                        className="form-control input-modal"
                        id="age"
                        name="age"
                        value={membersData.age}
                        onChange={handleModalInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="nationality"
                        className="form-label text-white"
                      >
                        Nationality
                      </label>
                      <input
                        type="text"
                        className="form-control input-modal"
                        id="nationality"
                        name="nationality"
                        value={membersData.nationality}
                        onChange={handleModalInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="salary" className="form-label text-white">
                        Salary
                      </label>
                      <input
                        type="text"
                        className="form-control input-modal"
                        id="salary"
                        name="salary"
                        value={membersData.salary}
                        onChange={handleModalInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="bonus" className="form-label text-white">
                      Bonus
                    </label>
                    <input
                      type="text"
                      className="form-control input-modal"
                      id="bonus"
                      name="bonus"
                      value={membersData.bonus}
                      onChange={handleModalInputChange}
                    />
                  </div>
                  </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="monthly_payment" className="form-label text-white">
                      Monthly Payment
                    </label>
                    <input
                      type="text"
                      className="form-control input-modal"
                      id="monthly_payment"
                      name="monthly_payment"
                      value={membersData.monthly_payment}
                      onChange={handleModalInputChange}
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                  <div className="mb-3">
                    <label htmlFor="img" className="col-form-label text-white">
                      Picture
                    </label>
                    <input
                      type="file"
                      className="form-control input-modal"
                      id="img"
                      name="img"
                      onChange={handleFileInputChange}
                    />
                  </div>
                </div>

                {/* Continue with your existing form elements */}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleSaveButton(player.id)}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={toggleModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </tr>
  );
};

export default PlayerRow;
