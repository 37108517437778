import React, { useState } from "react";
import img1 from "../assets/white_on_blue.png";
import icon1 from "../assets/icon1.png";
import icon2 from "../assets/icon2.png";
import icon3 from "../assets/icon3.png";
import icon4 from "../assets/icon4.png";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

const Sidebar = () => {
  const [isTeamDropdownVisible, setTeamDropdownVisible] = useState(false);
  const [isPlayerDropdownVisible, setPlayerDropdownVisible] = useState(false);
  const [isSettingsDropdownVisible, setSettingsDropdownVisible] =
    useState(false);

  const toggleTeamDropdown = () => {
    setTeamDropdownVisible(!isTeamDropdownVisible);
  };

  const togglePlayerDropdown = () => {
    setPlayerDropdownVisible(!isPlayerDropdownVisible);
  };

  const toggleSettingsDropdown = () => {
    setSettingsDropdownVisible(!isSettingsDropdownVisible);
  };
  return (
    <div>
      <div
        className="container px-5 py-3 height-side-bar-inner"
        style={{ overflowY: "auto", height: "100vw" }}
      >
        <img className="mt-2" src={img1} alt="logo" />
        <Link to="/" className="text-decor row mt-5 ">
          <div className="col-md-2">
            <img src={icon1} alt="" />
          </div>
          <div className="col-md-9 mt-1 dashboard-link">Dashboard</div>
        </Link>
        <Link className="text-decor-1 row mt-3 ">
          <div className="col-md-2">
            <img src={icon2} alt="" />
          </div>
          <div
            className="col-md-9 mt-1 dashboard-link"
            onClick={toggleTeamDropdown}
          >
            Team Management
            {isTeamDropdownVisible ? <IoIosArrowDown /> : null}
          </div>
        </Link>
        {isTeamDropdownVisible && (
          <div className="dropdown-content">
            {/* Add your dropdown content here */}
            <Link to="/add-team">My Teams</Link>
            {/* ... more dropdown items ... */}
          </div>
        )}
        <Link
          className={`text-decor-2 row mt-3 ${
            isPlayerDropdownVisible ? "active" : ""
          }`}
          onClick={togglePlayerDropdown}
        >
          <div className="col-md-2">
            <img src={icon3} alt="" />
          </div>
          <div className="col-md-9 mt-1 dashboard-link">
            Player Management{" "}
            {isPlayerDropdownVisible ? <IoIosArrowDown /> : null}
          </div>
        </Link>
        {isPlayerDropdownVisible && (
          <div className="dropdown-content">
            {/* Add your dropdown content here */}
            <Link to="/my-player">My Player List</Link>
            <Link to="/add-player">Add Player</Link>
          </div>
        )}

        <Link
          className={`text-decor-3 row mt-3 ${
            isSettingsDropdownVisible ? "active" : ""
          }`}
          onClick={toggleSettingsDropdown}
        >
          <div className="col-md-2">
            <img src={icon4} alt="" />
          </div>
          <div className="col-md-9 mt-1 dashboard-link">
            Settings {isSettingsDropdownVisible ? <IoIosArrowDown /> : null}
          </div>
        </Link>
        {isSettingsDropdownVisible && (
          <div className="dropdown-content">
            {/* Add your dropdown content here */}
            <Link to="/general-settings">General Settings</Link>
            <Link to="/coaches">Coaches</Link>
            <Link to="/add-managers">Add Managers</Link>
            <Link to="/position-list">Positon List</Link>
            <Link to="/add-user">User List</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
