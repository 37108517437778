import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";


const BudgetsTeamsTable = ({ team_id }) => {
  const { id } = useParams();
  const [teamData, setTeamData] = useState({
    id: null,
    user_id: null,
    team_name: "",
    budget: 0,
    extraSponsor: 0,
    salesIncome: 0,
    extra: 0,
    totalBudget: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data for the specific team based on team_id
        const response = await axios.get(
          `https://admin.smguld.com/api/v1/teams/${id}`
        );

        const team = response.data;
        // Calculate the total budget
        const totalBudget =
          team.budget + team.extraSponsor + team.salesIncome + team.extra;

        // Update state with team data
        setTeamData({
          id: team.id,
          user_id: team.user_id,
          team_name: team.team_name,
          budget: team.budget,
          extraSponsor: team.extraSponsor,
          salesIncome: team.salesIncome,
          extra: team.extra,
          totalBudget: totalBudget,
        });
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>{teamData.team_name} Budget Details</h2>
      <div style={styles.labelContainer}>
        <label style={styles.label}>Budget:</label>
        <input
          type="text"
          value={teamData.budget}
          readOnly
          style={styles.input}
        />
      </div>
      <div style={styles.labelContainer}>
        <label style={styles.label}>Extra Sponsor:</label>
        <input
          type="text"
          value={teamData.extraSponsor}
          readOnly
          style={styles.input}
        />
      </div>
      <div style={styles.labelContainer}>
        <label style={styles.label}>Sales Income:</label>
        <input
          type="text"
          value={teamData.salesIncome}
          readOnly
          style={styles.input}
        />
      </div>
      <div style={styles.labelContainer}>
        <label style={styles.label}>Extra:</label>
        <input
          type="text"
          value={teamData.extra}
          readOnly
          style={styles.input}
        />
      </div>
      <div style={styles.labelContainer}>
        <label style={styles.label}>Total Budget:</label>
        <input
          type="text"
          value={teamData.totalBudget}
          readOnly
          style={styles.input}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "400px",
    margin: "20px auto",
    padding: "20px",
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  header: {
    fontSize: "1.5em",
    color: "#333",
    marginBottom: "20px",
    textAlign: "center",
  },
  labelContainer: {
    marginBottom: "10px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    color: "#555",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
};

export default BudgetsTeamsTable;
